import styled, { css } from "styled-components";
import { PreTreColors } from "@frontend/common/src/styles/constants";

const activeItemStyle = css`
  color: ${PreTreColors.pretre};
  font-weight: 700;
`;

type ItemProps = {
  isActive?: boolean;
};

export const Item = styled("a")<ItemProps>`
  display: flex;
  padding: 1rem;
  justify-content: space-around;
  align-items: center;
  color: black;

  ${(props: ItemProps) => props.isActive && activeItemStyle}

  &:hover {
    color: ${PreTreColors.pretre};
  }
  cursor: pointer;
  text-decoration: none;
  margin-bottom: -10px;
`;

type ContainerProps = { isOverlay: boolean };

const overlayMenu = css`
  position: absolute;
  background: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
`;
const column = css`
  position: absolute;
  background: white;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ItemContainer = styled("div")<ContainerProps>`
  display: flex;
  ${(props: ContainerProps) => props.isOverlay && column}
`;

export const Container = styled("div")<ContainerProps>`
  margin-right: 1rem;
  margin-left: 1rem;
  min-height: 5rem;
  display: flex;
  justify-content: space-between;

  ${(props: ContainerProps) => props.isOverlay && overlayMenu}
`;

export const DropdownItem = styled("div")`
  display: flex;
  padding: 1rem;
  justify-content: space-around;
  align-items: center;
  color: black;
  &:hover {
    color: ${PreTreColors.pretre};
  }
  cursor: pointer;
  margin-bottom: -10px;
`;

export const BurgerBar = styled("div")`
  z-index: 1;
  cursor: pointer;
  justify-content: space-between;
  color: red;
  background-color: "red";
  width: 100%;
  color: "blue";
  display: flex;
  align-items: center;
`;
export const BurgerButton = styled("div")`
  z-index: 1;
  cursor: pointer;

  color: ${PreTreColors.pretre};
  width: 3rem;
  color: "blue";
  height: 3rem;

  display: flex;
  align-items: center;
  justify-content: center;
`;
