import React, { FunctionComponent } from "react";

type Props = {
  activeStep: any;
  steps: { title: string; slug: string }[];
};
export const BreadCrumbs: FunctionComponent<Props> = ({
  activeStep,
  steps,
}) => {
  return (
    <div
      style={{
        alignSelf: "center",
        padding: "2rem",
        color: "white",
        fontSize: "1.125rem",
        display: "inherit",
      }}
    >
      {steps.map((step, idx) => (
        <div key={idx}>
          <a
            style={{
              padding: "0 0.5rem",
              fontWeight:
                steps.indexOf(step) === activeStep ? "bold" : "normal",
            }}
            href={step.slug}
          >
            {step.title}
          </a>
          {steps.indexOf(step) < steps.length - 1 ? ">" : ""}
        </div>
      ))}
    </div>
  );
};
