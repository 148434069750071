import styled, { css } from "styled-components";
import { PreTreColors } from "../../styles/constants";

type ButtonProps = {
  secondary?: boolean;
  disabled?: boolean;
};

type ProductButton = {
  active: boolean;
  disabled: boolean;
};

export const Label = styled("label")`
  font-size: 18px;
  color: white;
`;

const disabledStyle = css`
  background-color: ${PreTreColors.borderlineGray};
  color: white;
  cursor: not-allowed;

  &:hover {
    background-color: ${PreTreColors.borderlineGray};
  }
`;

export const BlockButton = styled("button")<ButtonProps>`
  background-color: ${(props: ButtonProps) =>
    props.secondary ? PreTreColors.beis : PreTreColors.pretre};
  height: auto;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 700;
  color: white;
  padding: 15px 0;
  border-radius: 10px;
  border: 0;
  cursor: pointer;
  &:hover {
    background-color: ${(props: ButtonProps) =>
      props.secondary ? "#310802" : "#b30000"};
  }
  &:focus {
    outline: none;
    border: none;
    border-color: inherit;
    box-shadow: none;
  }

  ${(props: ButtonProps) => props.disabled && disabledStyle}
`;

export const LinkButton = styled("button")`
  margin: 1rem;
  background: none;
  border: none;
  width: 100%;
  text-align: center;
  font-size: 1.125rem;
  color: ${PreTreColors.beis};
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    opacity: 0.8;
  }
`;

export const SelectableBlockButton = styled("button")<ButtonProps>`
  background-color: ${PreTreColors.weakBrite};
  height: auto;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 700;
  color: ${PreTreColors.pretre};
  padding: 15px 0;
  border-radius: 10px;
  border: 1px solid ${PreTreColors.pretre};
  cursor: pointer;
  &:hover {
    background-color: ${PreTreColors.pretre};
    color: white;
  }

  ${(props: ButtonProps) => props.disabled && disabledStyle}
`;

export const ChooseProductPageButton = styled("button")<ProductButton>`
  background-color: ${(props: ProductButton) =>
    props.active ? PreTreColors.beis : "gray"};
  height: 3.5em;
  width: 13vw;
  font-size: 1.2rem;
  font-weight: 700;
  color: #fff;
  padding: 10px 0;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${PreTreColors.pretre};
    color: white;
  }
  ${(props: ProductButton) => props.disabled && disabledStyle}
`;
