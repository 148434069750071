import React from "react";
import ReactDOM from "react-dom";
import "./normalize-tailwind.css";
import "./index.css";
import { createOvermind } from "overmind";
import { Provider } from "overmind-react";
import { Auth0Provider } from "@auth0/auth0-react";

import { App } from "./app/App";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
import { config } from "./overmind/config";

const overmind = createOvermind(config, {
  devtools: "127.0.0.1:3031",
});

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
ReactDOM.render(
  <React.StrictMode>
    <Provider value={overmind}>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        redirectUri={`${window.location.origin}/auth`}
        audience="mg.iam"
      >
        <App />
      </Auth0Provider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
