import { Customer } from "@frontend/common/src/types/Customer";
import { endpoints } from "../endpoints";
import { ICustomerService } from "./ICustomerService";
import { RestClient } from "../../rest/RestClient";

export class CustomerService extends RestClient implements ICustomerService {
  async updateCustomer(customer: Customer): Promise<boolean> {
    return await this.makeRequest(endpoints.customer.update, {
      customerId: customer.id,
      adresse: customer.address,
      postnummer: customer.zipcode,
      epost: customer.email,
      epostBestillingsbekreftelse: customer.orderConfimEmail || null,
      epostOrdrebekreftelse: customer.orderConfrimEmailAlt || null,
      epostTegninger: customer.drawingsEmail || null,
      phone: customer.phone,
    });
  }

  async getById(customerId: string): Promise<Customer> {
    const results: any = await this.makeRequest(endpoints.customer.getById, {
      customerId,
    }).then((r) => r.data);
    return CustomerService.convert(results);
  }

  async getByToken(): Promise<Customer[]> {
    const results: any = await this.makeRequest(
      endpoints.customer.getForToken
    ).then((r) => r.data);
    return results.map((x: Customer) => CustomerService.convert(x));
  }

  static convert(customer: any): Customer {
    return {
      id: customer.kundenr,
      name: customer.kundenavn,
      address: customer.adresse1,
      zipcode: customer.postnrk,
      city: customer.poststed,
      email: customer.epost,
      drawingsEmail: customer.epost_Tegninger,
      orderConfimEmail: customer.epost_Bestillingsbekreftelse,
      orderConfrimEmailAlt: customer.epost_Ordrebekreftelse,
      phone: customer.telefon,
      constructionInformation: customer.std_konstr_opplysn,
      salesPersonId: customer.ansvarligSelger_ID,
    };
  }
}
