import { Customer } from "@frontend/common/src/types/Customer";
import { CustomerEmployee } from "@frontend/common/src/types/CustomerEmployee";

type State = {
  customerEmployeeSearchResult: CustomerEmployee[];
  customerSearchResult: Customer[];
};

export const state: State = {
  customerEmployeeSearchResult: [],
  customerSearchResult: [],
};
