import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { FormInput } from "@frontend/common/src/elements/FormInput/FormInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Label } from "@frontend/common/src/elements/Label/Label";
import {
  BlockButton,
  LinkButton,
} from "@frontend/common/src/elements/Buttons/Buttons.styles";
import * as validators from "@frontend/common/src/validators/index";
import { Page } from "@frontend/common/src/types/Page";
import { DateFormat, formatDate } from "@frontend/common/src/helpers/date";
import { Header } from "../../../elements/Header/Header";
import { useActions, useAppState, useEffects } from "../../../overmind";
import { Select } from "../../../components/Select/Select";

import { createUrl } from "../../../app/pages";
import { PageFlexConstraint } from "../ChooseProductPage/CreateOrderPage.styles";
import { DraftOrder } from "../../../types/DraftOrder/DraftOrder";
import { yesNoOptions } from "../../../data/selectOptions";
import { InputWrapper } from "../../../components/FormInput/FormInput.styles";

export const CustomerInformation: FunctionComponent = () => {
  const { router } = useEffects();
  const actions = useActions();
  const state = useAppState();
  const effects = useEffects();
  const { t } = useTranslation();

  const [sameAddressSelection, setSameAddressSelection] = useState(
    yesNoOptions[0].value
  );
  const isSameAddress = useMemo(
    () =>
      sameAddressSelection ===
      yesNoOptions.find((opt) => opt.label === "Ja").value,
    [sameAddressSelection]
  );
  const customerInformationSchema = useMemo(() => {
    return yup.object().shape({
      // Customer information
      customerContactId: validators.requiredField,
      marked: validators.requiredField,
      // TODO preferredDeliveryDate: validators.dateInFuture,

      // Construction site
      deliveryAddress: validators.address,
      zipCode: validators.requiredField,
      city: validators.requiredField,
      // city: validators.requiredField, // not required.
      farmNumber: validators.positiveNumeric,
      sumfarmNumber: validators.positiveNumeric,
      mamsl: validators.positiveNumeric,

      // deliveryAddressSameAsBuilding: validators.requiredField,
      arrivalSemi: !state.orders.currentNewOrder.quotation
        ? validators.requiredField
        : null,
      buildingType: !state.orders.currentNewOrder.quotation
        ? validators.requiredField
        : null,
      deliveryTerms: !state.orders.currentNewOrder.quotation
        ? validators.requiredField
        : null,
      preferredDeliveryDate: !state.orders.currentNewOrder.quotation
        ? validators.requiredField
        : null,

      deliveryAddress2:
        !isSameAddress && !state.orders.currentNewOrder.quotation
          ? validators.requiredField
          : null,
      zipCode2:
        !isSameAddress && !state.orders.currentNewOrder.quotation
          ? validators.requiredField
          : null,
      city2:
        !isSameAddress && !state.orders.currentNewOrder.quotation
          ? validators.requiredField
          : null,

      contactPersonPhoneNr: validators.phoneNumber8digits,
    });
  }, [isSameAddress, state.orders.currentNewOrder.quotation]);

  const { register, handleSubmit, errors, watch, setValue } =
    useForm<DraftOrder>({
      resolver: yupResolver(customerInformationSchema),
      defaultValues: {
        ...state.orders.autofill,
        customerContactId: state.orders.autofill?.customerContactId,
        preferredDeliveryDate: formatDate(
          state.orders.autofill?.preferredDeliveryDate,
          DateFormat.ISO_DATE
        ),
        ...state.orders.currentNewOrder,
      },
    });
  const streetAddress = watch("deliveryAddress");
  const zipCode = watch("zipCode");
  const zipCode2 = watch("zipCode2");
  const city = watch("city");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const city2 = watch("city2");

  const contactPersons: { label: string; value: any }[] = useMemo(() => {
    return state.customerEmployees
      .filter((c) => c.hasQuit === false)
      .map((c) => ({ label: `${c.name}`, value: c.id }));
  }, []);

  const deliveryOptions: { label: string; value: any }[] = useMemo(() => {
    return [
      { label: "Hentes fabrikk", value: "Hentes fabrikk" },
      {
        label: "Pretre leverer ihht. standard",
        value: "Pretre leverer ihht. standard",
      },
    ];
  }, []);

  const onSubmit = (data: DraftOrder) => {
    actions.orders.updateDraftOrder(data);
    router.navigateTo(createUrl(Page.CREATE_ORDER, { slug: "produktvalg" }));
  };

  useEffect(() => {
    if (zipCode && zipCode.length === 4) {
      effects.api.lookups
        .getCountyForZipcode(zipCode)
        .then((county) => {
          setValue("city", county);
        })
        .catch(() => {});
    }
  }, [zipCode]);

  useEffect(() => {
    if (zipCode2 && zipCode2.length === 4) {
      effects.api.lookups
        .getCountyForZipcode(zipCode2)
        .then((county) => {
          setValue("city2", county);
        })
        .catch(() => {});
    }
  }, [zipCode2]);

  const onDeliveryAddressSameAsBuildingChanged = (value: string) =>
    actions.orders.updateDraftOrder({
      deliveryAddressSameAsBuilding: value === "1",
    } as DraftOrder);

  useEffect(
    () => onDeliveryAddressSameAsBuildingChanged(sameAddressSelection),
    [sameAddressSelection]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageFlexConstraint>
        <div>
          <Header type="secondary">1. {t("Kundeinformasjon")}</Header>

          <FormInput
            disabled
            label="Kundenavn"
            type="text"
            name="customerName"
            defaultValue={state.customer.name}
          />
          <Select
            name="customerContactId"
            required
            label="Kontaktperson"
            options={contactPersons}
            inputRef={register}
            error={errors.customerContactId?.message}
          />
          <FormInput
            required
            label="Prosjektnavn"
            type="text"
            name="marked"
            inputRef={register}
            error={errors.marked?.message}
          />
          <FormInput
            name="preferredDeliveryDate"
            type="date"
            label={t("Ønsket leveringsdato")}
            inputRef={register}
            error={errors.preferredDeliveryDate?.message}
            required={!state.orders.currentNewOrder.quotation}
          />
          <FormInput
            label="Kundes bestillingsnr"
            type="text"
            name="customerOrderNumber"
            inputRef={register}
          />
        </div>

        {/** ********** */}
        {/* Section 2 */}
        {/** ********** */}

        <div>
          <Header type="secondary">2. Byggeplass</Header>
          <FormInput
            required
            label="Addresse"
            type="text"
            name="deliveryAddress"
            inputRef={register}
            error={errors.deliveryAddress?.message}
          />
          <div>
            <InputWrapper style={{ display: "flex" }}>
              <div style={{ marginRight: 10 }}>
                <FormInput
                  name="zipCode"
                  label="Postnummer"
                  type="number"
                  required
                  inputRef={register}
                  error={errors.zipCode?.message}
                />
              </div>
              <div style={{ marginLeft: 10 }}>
                <FormInput
                  name="city"
                  label="Poststed"
                  required
                  type="text"
                  inputRef={register}
                  error={errors.city?.message}
                />
              </div>
            </InputWrapper>
          </div>
          <div style={{ marginTop: 10 }}>
            <InputWrapper style={{ display: "flex" }}>
              <div style={{ marginRight: 10 }}>
                <FormInput
                  label="Gardsnr"
                  name="farmNumber"
                  inputRef={register}
                  type="text"
                />
              </div>
              <div style={{ marginLeft: 10 }}>
                <FormInput
                  label="Bruksnr"
                  name="subfarmNumber"
                  inputRef={register}
                  type="text"
                />
              </div>
            </InputWrapper>
          </div>
          <div style={{ marginTop: 10 }}>
            <Label>Høyde over havet</Label>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
              <div style={{ marginRight: 10 }}>
                <FormInput
                  type="number"
                  name="mamsl"
                  inputRef={register}
                  error={errors.mamsl?.message}
                />
              </div>

              {streetAddress && zipCode && city ? (
                <BlockButton
                  as="a"
                  style={{
                    fontSize: "1rem",
                    padding: "0rem",
                    margin: "1rem 0.5rem .5rem",
                    textAlign: "center",
                    paddingTop: ".9rem",
                  }}
                  href={`https://www.norgeskart.no/#!?project=norgeskart&zoom=13&layers=panel=searchOptionsPanel&sok=${streetAddress}, ${zipCode} ${city}`}
                  target="_blank"
                >
                  Norgeskart
                </BlockButton>
              ) : (
                <BlockButton
                  disabled
                  style={{
                    fontSize: "1rem",
                    padding: "0rem",
                    margin: "1rem 0.5rem .5rem",
                  }}
                >
                  Norgeskart
                </BlockButton>
              )}
            </div>
          </div>
          <FormInput
            label="Kontaktperson byggeplass"
            type="text"
            name="contactPersonSite"
            inputRef={register}
          />
          <FormInput
            label="Telefon byggeplass"
            type="text"
            name="contactPersonPhoneNr"
            inputRef={register}
            error={errors.contactPersonPhoneNr?.message}
          />
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
          <div>
            <Header type="secondary">3. Leveringsaddresse</Header>

            <Select
              name="deliveryAddressSameAsBuilding"
              required={!state.orders.currentNewOrder.quotation}
              label="Samme som byggeplass?"
              options={yesNoOptions}
              onSelectionChanged={(val) => setSameAddressSelection(val)}
              error={errors.deliveryAddressSameAsBuilding?.message}
            />

            <FormInput
              label="Adresse"
              name="deliveryAddress2"
              type="text"
              inputRef={register}
              required={!isSameAddress}
              disabled={isSameAddress}
              error={errors?.deliveryAddress2?.message}
            />

            <div>
              <InputWrapper style={{ display: "flex" }}>
                <div style={{ marginRight: 10 }}>
                  <FormInput
                    label="Postnummer"
                    name="zipCode2"
                    type="number"
                    required={!isSameAddress}
                    inputRef={register}
                    disabled={isSameAddress}
                    error={errors?.zipCode2?.message}
                  />
                </div>
                <div style={{ marginLeft: 10 }}>
                  <FormInput
                    label="Poststed"
                    name="city2"
                    type="text"
                    required={!isSameAddress}
                    inputRef={register}
                    disabled={isSameAddress}
                    error={errors?.city2?.message}
                  />
                </div>
              </InputWrapper>
            </div>
          </div>

          <div>
            <Header type="secondary">4. Annet</Header>
            <Select
              required={!state.orders.currentNewOrder.quotation}
              name="arrivalSemi"
              label="Tilkomst for semi på byggeplass?"
              options={yesNoOptions}
              inputRef={register}
              error={errors.arrivalSemi?.message}
            />

            <Select
              name="deliveryTerms"
              required={!state.orders.currentNewOrder.quotation}
              label="Levering"
              inputRef={register}
              options={deliveryOptions}
              error={errors.deliveryTerms?.message}
            />

            <Select
              name="buildingType"
              required={!state.orders.currentNewOrder.quotation}
              label="Byggtype"
              inputRef={register}
              options={state.buildingTypes.map((p) => ({
                label: p.name,
                value: p.id,
              }))}
              error={errors.buildingType?.message}
            />

            <div
              style={{
                display: "flex",
                width: "30vw",
                marginTop: "2rem",
                position: "relative",
                bottom: 0,
              }}
            >
              <BlockButton
                // onClick={() => router.navigateTo(createUrl(Page.CREATE_ORDER, { slug: "produktvalg" }))}
                onClick={() => false}
                style={{ width: "1000px" }}
                type="submit"
              >
                Neste
              </BlockButton>
              <LinkButton onClick={() => actions.orders.cancelCreateOrder()}>
                Avbryt
              </LinkButton>
            </div>
          </div>
        </div>
      </PageFlexConstraint>
    </form>
  );
};
