import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { TextArea } from "@frontend/common/src/components/TextArea/TextArea";
import * as validators from "@frontend/common/src/validators";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppState } from "../../../overmind";

import { Select } from "../../../components/Select/Select";

import { FormProps } from "./RoofForm";
import { BlockButton } from "../../../components/Buttons/Buttons.styles";
import { DraftOrderSubProject } from "../../../types/DraftOrder/DraftOrderSubProject";
import { makeId } from "../../../helpers/GenerateId";

const technicalServices = yup.object().shape({
  technical: validators.string,
  comment: validators.string,
});

export const TechnicalServicesForm: FunctionComponent<FormProps> = ({
  setSubProjects,
  setActiveForm,
  subProjects,
  selectedSubProject,
  setSelectedSubProject,
  autoFill,
}) => {
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { offerOptions } = useAppState();
  const onSubmit = (data: DraftOrderSubProject) => {
    if (data.projectId === undefined || data.projectId.length < 1) {
      // eslint-disable-next-line no-param-reassign
      data.projectId = makeId(8);
    }
    if (selectedSubProject !== undefined) {
      const newProjs = subProjects.map((x) => {
        if (x.projectId === data.projectId) {
          return {
            ...x,
            comment: data.comment,
            technicalType: data.technicalType,
          };
        }
        return x;
      });
      setSubProjects(newProjs);
    } else {
      setSubProjects([...subProjects, data]);
    }
    setActiveForm("");
    setSelectedSubProject(undefined);
  };
  const onCancel = () => {
    setActiveForm("");
    setSelectedSubProject(undefined);
  };

  const technical = [
    {
      label: "Arbeidstegninger",
      value: "1",
    },
    {
      label: "Søknadstegninger",
      value: "2",
    },
    {
      label: "Teknisk rådgiving",
      value: "3",
    },
    {
      label: "Byggesøknad",
      value: "4",
    },
    {
      label: "Mengdeberegning",
      value: "5",
    },
    {
      label: "Energiberegning",
      value: "6",
    },
    {
      label: "Energimerking",
      value: "7",
    },
    {
      label: "Statiske beregninger",
      value: "8",
    },
    {
      label: "Stabilitetsberegning",
      value: "9",
    },
  ];
  const { register, handleSubmit, errors } = useForm<DraftOrderSubProject>({
    resolver: yupResolver(technicalServices),
    defaultValues: selectedSubProject,
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          width: "100%",
        }}
      >
        <div>
          <input ref={register} type="hidden" value="technical" name="type" />

          <Select
            name="technicalType"
            label={t("TechnicalType")}
            options={technical}
            inputRef={register}
            error={errors.dimensions?.message}
            required
            disabled={
              autoFill && selectedSubProject.technicalType !== undefined
            }
          />

          <TextArea
            label={t("Kommentar")}
            name="comment"
            rows={3}
            inputRef={register}
            placeholder={t("Write other required info here")}
            defaultValue={errors.comment?.message}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            width: "30%",
          }}
        >
          <BlockButton style={{ marginBottom: "2em" }} type="submit">
            {selectedSubProject !== undefined ? "Oppdater" : "Legg til"}
          </BlockButton>
          <BlockButton onClick={() => onCancel()} type="button">
            Avbryt
          </BlockButton>
        </div>
      </div>
    </form>
  );
};
