/* eslint-disable no-param-reassign */
import { UserType } from "@frontend/common/src/types/User";
import { Context } from "..";

export const searchForCustomerEmployee = async (
  { effects, state }: Context,
  partial: string
) => {
  try {
    const result = await effects.api.admin.search(partial);
    state.admin.customerEmployeeSearchResult = result.customerEmployees;
    state.admin.customerSearchResult = result.customers;
  } catch (errorMessage: any) {
    effects.toast(errorMessage, 5000);
  }
};

export const emulateCustomer = async (
  { state, effects }: Context,
  customerId: string
) => {
  state.customer = await effects.api.customer.getById(customerId);
  state.customerEmployees =
    await effects.api.customerEmployee.getAllForCustomerId(customerId);
  state.auth.user.emulatedType = UserType.COMPANY;
};

export const clearEmulation = async (
  { state, effects }: Context,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  customerId: number
) => {
  state.customer = await effects.api.customer.getById(
    state.auth.user.customerId
  );
  state.customerEmployees =
    await effects.api.customerEmployee.getAllForCustomerId(
      state.auth.user.customerId
    );
  if (state.auth.user.type === UserType.EMPLOYEE && state.auth.user.id) {
    state.currentEmployeeId = state.auth.user.id;
  }
  state.auth.user.emulatedType = undefined;
};
