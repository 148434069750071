import React, { FunctionComponent } from "react";
import { Column, Table } from "@frontend/common/src/components/Table/Table";
import { Direction } from "@frontend/common/src/components/Table/Direction";
import {
  BlockButton,
  SelectableBlockButton,
} from "@frontend/common/src/elements/Buttons/Buttons.styles";
import { Activity } from "@frontend/common/src/types/Activity";
import { formatDate } from "@frontend/common/src/helpers/date";
import { useActions } from "../../../../overmind";

type ColumnKey = keyof Activity;

type Props = {
  activities: Activity[];
  columns?: ColumnKey[];
  // eslint-disable-next-line no-unused-vars
  onRowSelected?: (row: Activity) => void;
  onCreateActivity?: () => void;
};

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
const createColumns = (actions: any): Column<Activity>[] => {
  const allColumns: Column<Activity>[] = [
    {
      headerName: "Dato",
      field: "regDate",
      render: (row) => `${formatDate(row.regDate)}`,
    },
    {
      headerName: "Type",
      field: "taskTypeName",
    },
    {
      headerName: "Overskrift",
      field: "headline",
    },
  ];

  return allColumns;
};

export const CrmActivities: FunctionComponent<Props> = ({
  activities,
  columns,
  onRowSelected,
  onCreateActivity,
}) => {
  const actions = useActions();
  const allColumns = createColumns(actions);
  let columnsToUse = null;
  if (!columns) {
    columnsToUse = allColumns;
  } else {
    columnsToUse = columns.map((c) => allColumns.find((a) => a.field === c));
  }
  const [sortOption, setSortOption] = React.useState<{
    field: keyof Activity;
    direction: Direction;
  }>({
    field: "regDate",
    direction: Direction.descending,
  });

  return (
    <>
      {/* Tabs (Menu options) */}
      <div
        style={{
          display: "flex",
          width: "100%",
          maxWidth: "40rem",
          marginBottom: "1rem",
        }}
      >
        <SelectableBlockButton
          style={{ marginRight: "1rem" }}
          onClick={() => actions.onChangeCrmActiveStep(0)}
        >
          Kontaktpersoner
        </SelectableBlockButton>
        <BlockButton>Aktiviteter</BlockButton>
      </div>

      <BlockButton
        onClick={onCreateActivity}
        style={{
          width: "100%",
          maxWidth: "8rem",
          padding: "0.5rem",
          fontSize: "1rem",
          marginBottom: "1rem",
        }}
      >
        Ny aktivitet
      </BlockButton>

      <Table
        keyField="id"
        columns={columnsToUse}
        availableColumns={allColumns}
        data={[...activities].sort((a, b) => {
          if (sortOption) {
            if (sortOption.direction === Direction.ascending) {
              return a[sortOption.field] > b[sortOption.field] ? 1 : -1;
            }
            return a[sortOption.field] < b[sortOption.field] ? 1 : -1;
          }
          return 0;
        })}
        onSortChanged={(field, direction) =>
          setSortOption({ field, direction })
        }
        currentSortOption={sortOption}
        onRowSelected={onRowSelected}
      />
    </>
  );
};
