import React, { FunctionComponent } from "react";

import { Grid, Row, Col } from "@frontend/common/src/elements/Grid/Grid";
import { useTranslation } from "react-i18next";
import { Page } from "@frontend/common/src/elements/Page/Page";

import { formatDate } from "@frontend/common/src/helpers/date";
import { Page as PageConstants } from "@frontend/common/src/types/Page";
import { CMSArticles } from "@frontend/common/src/types/CMSArticle";
import { PhoneLink } from "@frontend/common/src/components/PhoneLink/PhoneLink";
import { CMSContent } from "../../../components/CMSContent/CMSContent";
import { DocumentList } from "../../../components/DocumentList/DocumentList";
import { OrderList } from "../../../components/OrderList/OrderList";

import {
  DetailsBox,
  Legend,
  Text,
  Group,
} from "../../../components/DetailsBox/DetailsBox";
import { SketchFabEmbed } from "../../../components/SketchFabEmbed/SketchFabEmbed";
import { useActions, useAppState } from "../../../overmind";
import { Link } from "../../../elements/Link/Link";
import { Header } from "../../../elements/Header/Header";
import { createUrl } from "../../../app/pages";
import { formatMoney } from "../../../helpers/money";
import { getDeliveryDateText } from "../../../helpers/order";
import { router } from "../../../overmind/effects";
import {
  BlockButton,
  LinkButton,
} from "../../../components/Buttons/Buttons.styles";
import { PrecutModal } from "../../../modals/PrecutModal/PrecutModal";
import { FileUploadModal } from "../../../modals/FileUploadModal/FileUploadModal";

export const ViewOrderPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const actions = useActions();
  const {
    customerEmployees,
    customer,
    orders: { orders, currentOrder, masterProjects },
    modals: { precutModal, fileUploadModal },
  } = useAppState();

  if (!currentOrder) {
    return <div>{t("Loading")}</div>;
  }

  const customerContact = customerEmployees.find(
    (e) => e.id === currentOrder.customerEmployeeId
  );

  const ordersInSameProject = orders
    .find((x) => x.id === currentOrder.projectId)
    .subProjects.filter((o) => o.id !== currentOrder.id);

  const backLink = masterProjects[currentOrder.projectId]
    ? {
        label: `Prosjekt #${currentOrder.projectId}`,
        url: createUrl(PageConstants.VIEW_ORDER_PROJECT, {
          id: masterProjects[currentOrder.projectId],
        }),
      }
    : null;

  const onNewOrderClicked = async () => {
    await actions.orders.getOrderAutofill({
      customerId: customer.id,
      offerId: currentOrder.projectId,
    });
    actions.orders.setOrderConverted(true);
    router.navigateTo(
      createUrl(PageConstants.CREATE_ORDER, { slug: "kundeinfo" })
    );
  };
  return (
    <Page title={`Prosjekt #${currentOrder.id}`} backLink={backLink}>
      <Grid>
        <Row>
          <Col size={1}>
            <DetailsBox>
              <tbody>
                <Group>
                  <Legend>{t("Created")}</Legend>
                  <Text>{formatDate(currentOrder.orderDate)}</Text>
                </Group>
                <Group>
                  <Legend>{t("Product")}</Legend>
                  <Text>
                    {currentOrder.product && currentOrder.product !== ""
                      ? currentOrder.product
                      : "Ukjent"}
                  </Text>
                </Group>
                <Group>
                  <Legend>{t("Status")}</Legend>
                  <Text>{currentOrder.status}</Text>
                </Group>
                <Group>
                  <Legend>{t("Delivery date")}</Legend>
                  <Text>{getDeliveryDateText(currentOrder)}</Text>
                </Group>

                <Group>
                  <Legend>Prosjektnavn</Legend>
                  <Text>{currentOrder.projectName}</Text>
                </Group>
                <Group>
                  <Legend>{t("Customer order no")}</Legend>
                  <Text>{currentOrder.customerOrderNumber || "Ikke satt"}</Text>
                </Group>
                <Group>
                  <Legend>{t("Customer ref.")}</Legend>
                  <Text>
                    {customerContact ? customerContact.name : "Ikke satt"}
                  </Text>
                </Group>
                <Group>
                  <Legend>{t("Delivery addr.")}</Legend>
                  {currentOrder.deliveryTerms.toLowerCase() === "avhentet" ? (
                    <Text>Avhentet</Text>
                  ) : (
                    <Text>
                      <Link
                        target="_blank"
                        href={`https://www.google.no/maps/search/${encodeURI(
                          currentOrder.deliveryAddress
                        )}`}
                      >
                        {currentOrder.deliveryAddress}
                      </Link>
                    </Text>
                  )}
                </Group>
                <Group>
                  <Legend>{t("Order price")}</Legend>
                  <Text>
                    {currentOrder.isOrderPriceCalculated &&
                    currentOrder.sum > 0 ? (
                      <span style={{ display: "flex" }}>
                        {formatMoney(currentOrder.sum)}
                        <CMSContent contentId={CMSArticles.NOT_CALCULATED} />
                      </span>
                    ) : (
                      formatMoney(currentOrder.sum)
                    )}
                  </Text>
                </Group>
              </tbody>
            </DetailsBox>
          </Col>
          <Col size={1}>
            <DetailsBox>
              <tbody>
                <Group>
                  <Legend>{t("Constructor")}</Legend>
                  <Text>{currentOrder.constructionName}</Text>
                </Group>
                <Group>
                  <Legend>{t("Site phone")}</Legend>
                  <Text>
                    <PhoneLink number={currentOrder.constructionContactPhone} />
                  </Text>
                </Group>
                <Group>
                  <Legend>{t("Site contact")}</Legend>
                  <Text>{currentOrder.constructionContactName}</Text>
                </Group>
                <Group>
                  <Legend>Ønsket leveringsdato</Legend>
                  <Text>{getDeliveryDateText(currentOrder)}</Text>
                </Group>
              </tbody>
            </DetailsBox>
            {currentOrder.valueChainStatus === 1 && (
              <BlockButton
                style={{
                  width: "33%",
                  height: "25%",
                  fontSize: "1rem",
                  padding: "10px 0",
                }}
                onClick={() => onNewOrderClicked()}
              >
                {" "}
                Sett tilbud til ordre{" "}
              </BlockButton>
            )}
            {currentOrder.valueChainStatus === 4 && (
              <BlockButton
                style={{
                  width: "33%",
                  height: "25%",
                  fontSize: "1rem",
                  padding: "10px 0",
                }}
              >
                {" "}
                Godkjenn ordre{" "}
              </BlockButton>
            )}
          </Col>

          <Col size={1}>
            {currentOrder.embedId ? (
              <SketchFabEmbed
                embedHash={currentOrder.embedHash}
                embedId={currentOrder.embedId}
              />
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col size={2}>
            <div style={{ marginBottom: 10 }}>
              <Header type="secondary">{t("Other orders in project")}</Header>
            </div>
            <OrderList
              noResultsText="Ingen andre ordre i prosjektet"
              orders={ordersInSameProject}
              columns={[
                "id",
                "projectName",
                "status",
                "delivery",
                "constructionName",
              ]}
            />
          </Col>

          <Col size={1}>
            <div style={{ marginBottom: 10 }}>
              <Header type="secondary">{t("Documents")}</Header>
            </div>
            {currentOrder.valueChainStatus === 0 && (
              <LinkButton
                as="a"
                onClick={() => actions.promptFileUpload(currentOrder.projectId)}
              >
                Legg til
              </LinkButton>
            )}
            <DocumentList documents={currentOrder.documents} />
          </Col>
        </Row>
      </Grid>
      {fileUploadModal.isCurrent ? <FileUploadModal /> : null}
      {precutModal.isCurrent ? <PrecutModal /> : null}
    </Page>
  );
};
