import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Col, Grid, Row } from "@frontend/common/src/elements/Grid/Grid";
import { FormInput } from "@frontend/common/src/elements/FormInput/FormInput";
import { Activity } from "@frontend/common/src/types/Activity";
import { TextArea } from "@frontend/common/src/components/TextArea/TextArea";
import { BlockButton } from "@frontend/common/src/elements/Buttons/Buttons.styles";
import * as validators from "@frontend/common/src/validators";
import { Select } from "@frontend/common/src/components/Select/Select";
import { Employee } from "@frontend/common/src/types/Employee";
import { useAppState } from "../../../overmind";

const completedOpts = [
  {
    key: 1,
    label: "Ja",
    value: true,
  },
  {
    key: 2,
    label: "Nei",
    value: false,
  },
];
const validationSchema = yup.object().shape({
  headline: validators.string,
  taskTypeId: validators.string,
  completed: validators.string,
  customerContactId: validators.string,
  note: validators.string,
  regDate: validators.string,
});

type Props = {
  activity: Activity;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: Activity) => void;
};

export const ActivityForm: FunctionComponent<Props> = ({
  activity,
  onSubmit,
}) => {
  const [defaultEmployee, setDefaultEmployee] = useState<number | undefined>(
    undefined
  );

  const state = useAppState();

  const { register, handleSubmit, errors } = useForm<Activity>({
    resolver: yupResolver(validationSchema),
    defaultValues: activity,
  });

  const contactPersons: { label: string; value: any }[] = useMemo(() => {
    return state.crmCustomerEmployees
      .filter((c) => c.hasQuit === false)
      .map((c) => ({
        label: `${c.name}`,
        value: c.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [state.customerEmployees]);

  const eligibleCompletionEmployees: { label: string; value: any }[] =
    useMemo(() => {
      if (state.pretreEmplyees && state.pretreEmplyees.length > 0) {
        return state.pretreEmplyees
          .filter((e: Employee) => e.hide === false)
          .map((c) => ({
            label: `${c.name}`,
            value: c.id,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
      }
      return [];
    }, [state.pretreEmplyees]);

  useEffect(() => {
    if (eligibleCompletionEmployees.length > 0) {
      const foundDefaultEmployee = eligibleCompletionEmployees.find(
        (e: any) => e.email === state.auth.user.username
      );
      if (foundDefaultEmployee) {
        setDefaultEmployee(foundDefaultEmployee.value);
      }
    }
  }, [eligibleCompletionEmployees]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(onSubmit)(e);
      }}
    >
      <Grid>
        <Row>
          <Col size={1}>
            <input type="hidden" name="active" ref={register} />
            <FormInput
              required
              label="Overskrift"
              type="text"
              name="headline"
              inputRef={register}
              error={errors.headline?.message}
            />
            <FormInput
              required
              label="Dato"
              type="date"
              name="regDate"
              inputRef={register}
              error={errors.regDate?.message}
            />

            <Select
              name="taskTypeId"
              required
              label="Type"
              options={state.crmActivityTypes.map((a) => {
                return { label: `${a.name}`, value: a.id };
              })}
              inputRef={register}
              error={errors.taskTypeId?.message}
            />
            <Select
              name="completed"
              label="Utført (Ja/Nei)"
              options={completedOpts.map((o) => {
                return { label: `${o.label}`, value: o.value };
              })}
              inputRef={register}
              error={errors.completed?.message}
            />
            <Select
              name="pretreEmployeeId"
              label="Utført av"
              options={eligibleCompletionEmployees}
              selectedValue={defaultEmployee}
              inputRef={register}
              error={errors.pretreEmployeeId?.message}
            />
            <Select
              name="customerContactId"
              label="Kundekontakt"
              options={contactPersons}
              selectedValue={activity?.customerContactId || undefined}
              inputRef={register}
              error={errors.customerContactId?.message}
            />
            <TextArea label="Notat" name="note" rows={4} inputRef={register} />
          </Col>
        </Row>
      </Grid>

      <div style={{ display: "flex" }}>
        <BlockButton type="submit">
          {activity != null ? "Oppdater" : "Opprett"}
        </BlockButton>
      </div>
    </form>
  );
};
