export const editEmployeeModal = {
  state: { employeeId: null as string },
  result: null as void,
};

export const editActivityModal = {
  state: { activityId: null as string },
  result: null as void,
};

export const sendMessageModal = {
  state: { userId: null as string },
  result: null as string,
};
