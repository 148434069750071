import { Activity, ActivityType } from "@frontend/common/src/types/Activity";
import { Customer } from "@frontend/common/src/types/Customer";
import { CustomerEmployee } from "@frontend/common/src/types/CustomerEmployee";
import { CMSArticle } from "@frontend/common/src/types/CMSArticle";
import { Employee } from "@frontend/common/src/types/Employee";
import { derived } from "overmind";
import { CrmSteps } from "../types/CrmSteps";

type State = {
  isInitialLoadComplete: boolean;
  activeCrmStep: CrmSteps;
  crmCustomer: Customer | undefined;
  crmCustomerEmployees: CustomerEmployee[];
  crmCustomerActivities: Activity[];
  crmActivityTypes: ActivityType[];
  pretreEmplyees: Employee[];
  customerEmployees: CustomerEmployee[];
  customer: Customer;
  currentEmployeeId: string;
  currentEmployee: CustomerEmployee;
  error: { message: string };
  cms: { [index: number]: CMSArticle };
  mesterIdLogin: boolean;
  mesterIdCustomers: Customer[];
};

export const state: State = {
  isInitialLoadComplete: false,
  activeCrmStep: CrmSteps.CONTACT_PERSONS,
  currentEmployeeId: null,
  cms: {},
  pretreEmplyees: [],
  customerEmployees: [],
  customer: null,
  crmCustomer: null,
  crmCustomerEmployees: [],
  crmCustomerActivities: [],
  crmActivityTypes: [],
  // eslint-disable-next-line no-shadow
  currentEmployee: derived((state: State) => {
    return state.customer
      ? state.customerEmployees.find((e) => e.id === state.currentEmployeeId)
      : null;
  }),
  error: null,
  mesterIdLogin: false,
  mesterIdCustomers: [],
};
