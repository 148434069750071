import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Row, Col } from "@frontend/common/src/elements/Grid/Grid";
import { FormInput } from "@frontend/common/src/elements/FormInput/FormInput";
import { Page } from "@frontend/common/src/elements/Page/Page";

import {
  BlockButton,
  LinkButton,
} from "@frontend/common/src/elements/Buttons/Buttons.styles";
import { Header } from "@frontend/common/src/elements/Header/Header";
import { useActions, useAppState } from "../../../overmind";
import { CustomerEmployeeList } from "./components/CustomerEmployeeList/CustomerEmployeeList";
import { CustomerList } from "./components/CompanyList/CustomerList";

export const CustomerListPage: FunctionComponent = () => {
  const state = useAppState();
  const actions = useActions();

  const { t } = useTranslation();

  const [term, setTerm] = useState("");
  return (
    <Page title={t("Kunder og kontakter")}>
      <Grid>
        <Row>
          <Col
            size={2}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                actions.admin.searchForCustomerEmployee(term);
              }
            }}
          >
            <FormInput
              placeholder={t("Search")}
              type="text"
              name="search"
              defaultValue={term}
              onChange={setTerm}
            />
          </Col>

          <Col size={1}>
            <BlockButton
              onClick={() => {
                actions.admin.searchForCustomerEmployee(term);
              }}
            >
              Søk
            </BlockButton>
            {state.customer &&
            state.customer.id !== state.auth.user?.customerId ? (
              <LinkButton onClick={() => actions.admin.clearEmulation()}>
                Avslutt
              </LinkButton>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col size={1}>
            <Header>Personer</Header>
            <CustomerEmployeeList
              customerEmployees={state.admin.customerEmployeeSearchResult}
            />
          </Col>
          <Col size={1}>
            <Header>Bedrifter</Header>
            <CustomerList customers={state.admin.customerSearchResult} />
          </Col>
        </Row>
      </Grid>
    </Page>
  );
};
