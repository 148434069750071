/* eslint-disable no-param-reassign */
import { Activity } from "@frontend/common/src/types/Activity";
import { Page } from "@frontend/common/src/types/Page";
import { Customer } from "@frontend/common/src/types/Customer";
import { UpdateCustomerEmployee } from "@frontend/common/src/types/CustomerEmployee";
import { CreateEmployeeMessage } from "@frontend/common/src/types/EmployeeMessage";
import { Context } from ".";
import { getNavigationRoute } from "../app/pages";
// import { OnInitialize } from "./index";

export const onContactPerson = async (
  { actions, effects, state }: any,
  createEmployeeMessage: CreateEmployeeMessage
) => {
  const message = await actions.modals.sendMessageModal.open({
    userId: createEmployeeMessage.employeeId,
  });

  createEmployeeMessage.loggedInEmail = state.auth.user.username;
  if (message && message.length) {
    createEmployeeMessage.message = message;
    try {
      await effects.api.employees.sendMessage(createEmployeeMessage);
      effects.toast("Melding sendt", 5000);
    } catch (error) {
      effects.toast(error, 5000);
    }
  }
};

export const onEditEmployee = async (
  { actions }: Context,
  employeeId: string
) => {
  actions.modals.editEmployeeModal.open({ employeeId });
};

export const onEditActivity = async (
  { actions }: Context,
  activityId: string
) => {
  actions.modals.editActivityModal.open({ activityId });
};

export const changeCrmUserActive = async (
  { state, effects }: Context,
  params: { userId: string; activeState: boolean }
) => {
  const employee = state.crmCustomerEmployees.find(
    (c) => c.id === params.userId
  );
  employee.active = params.activeState;

  try {
    await effects.api.customerEmployee.update(state.crmCustomer.id, {
      ...employee,
    });
    state.crmCustomerEmployees =
      await effects.api.customerEmployee.getAllForCustomerId(
        state.crmCustomer.id
      );
  } catch (errorMessage: any) {
    effects.toast(errorMessage, 5000);
  }
};

export const changeCustomer = async (
  { state, effects }: Context,
  customer: Customer
) => {
  try {
    await effects.api.customer.updateCustomer(customer);

    state.customer = {
      ...state.customer,
      ...customer,
    };
    effects.toast("Lagret", 5000);
  } catch (errorMessage: any) {
    effects.toast(errorMessage, 5000);
  }
};

export const changeCustomerEmployee = async (
  { state, effects }: Context,
  customerEmployee: UpdateCustomerEmployee
) => {
  try {
    await effects.api.customerEmployee.update(
      state.customer.id,
      customerEmployee
    );
    state.customerEmployees =
      await effects.api.customerEmployee.getAllForCustomerId(state.customer.id);
    effects.toast("Lagret", 5000);
  } catch (errorMessage: any) {
    effects.toast(errorMessage, 5000);
  }
};

export const createActivity = async (
  { state, effects }: Context,
  activity: Activity
) => {
  try {
    await effects.api.activity.create(state.crmCustomer.id, activity);
    state.crmCustomerActivities = await effects.api.activity.getAllByCustomerId(
      state.crmCustomer.id
    );
    effects.toast("Lagret", 5000);
  } catch (errorMessage: any) {
    effects.toast(errorMessage, 5000);
  }
};

export const updateActivity = async (
  { state, effects }: Context,
  activity: Activity
) => {
  try {
    await effects.api.activity.update(state.crmCustomer.id, activity);
    state.crmCustomerActivities = await effects.api.activity.getAllByCustomerId(
      state.crmCustomer.id
    );
    effects.toast("Lagret", 5000);
  } catch (errorMessage: any) {
    effects.toast(errorMessage, 5000);
  }
};

export const adminCreateCustomerEmployee = async (
  { state, effects }: Context,
  customerEmployee: UpdateCustomerEmployee
) => {
  try {
    await effects.api.customerEmployee.create(
      state.crmCustomer.id,
      customerEmployee
    );
    state.crmCustomerEmployees =
      await effects.api.customerEmployee.getAllForCustomerId(
        state.crmCustomer.id
      );
    effects.toast("Opprettet!", 5000);
  } catch (errorMessage: any) {
    effects.toast(errorMessage, 5000);
  }
};

export const createCustomerEmployee = async (
  { state, effects }: Context,
  customerEmployee: UpdateCustomerEmployee
) => {
  try {
    await effects.api.customerEmployee.create(
      state.customer.id,
      customerEmployee
    );
    state.customerEmployees =
      await effects.api.customerEmployee.getAllForCustomerId(state.customer.id);
    effects.toast("Opprettet!", 5000);
  } catch (errorMessage: any) {
    effects.toast(errorMessage, 5000);
  }
};

export const onChangeCrmActiveStep = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state, effects }: Context,
  step: number
) => {
  state.activeCrmStep = step;
  // TODO Update url ?
  // if (step === 0) {
  //   effects.router.navigateTo(createUrl(Page.CRM, { customerId: state.crmCustomer.id, step: "personer" }));
  // } else {
  //   effects.router.navigateTo(createUrl(Page.CRM, { customerId: state.crmCustomer.id, step: "aktiviteter" }));
  // }
};

export const onResetPassword = async (
  { state, effects }: Context,
  customerEmployeeId: string
) => {
  try {
    await effects.api.customerEmployee.resetPassword(
      state.crmCustomer?.id ?? state.customer.id,
      customerEmployeeId
    );
    effects.toast("Tilbakestilt passord", 5000);
  } catch (errorMessage: any) {
    effects.toast(errorMessage, 5000);
  }
};

export const changeCrmUserHasQuit = async (
  { state, effects }: Context,
  params: { userId: string; hasQuit: boolean }
) => {
  const employee = state.crmCustomerEmployees.find(
    (c) => c.id === params.userId
  );

  employee.hasQuit = params.hasQuit;

  try {
    await effects.api.customerEmployee.update(state.crmCustomer.id, {
      ...employee,
    });
    state.crmCustomerEmployees =
      await effects.api.customerEmployee.getAllForCustomerId(
        state.crmCustomer.id
      );
  } catch (errorMessage: any) {
    effects.toast(errorMessage, 5000);
  }
};

export const onInitializeOvermind = async ({
  state,
  actions,
  effects,
}: Context) => {
  const cms = await effects.cms.fetch(
    "https://www.pretre.no/kundeportal/?xml=document"
  );
  state.cms = cms;

  state.isInitialLoadComplete = true;

  const { router } = effects;

  router.route(getNavigationRoute(Page.LOGIN), actions.navigation.openLogin);
  router.route(
    getNavigationRoute(Page.ADMIN),
    actions.navigation.openAdminPage
  );
  router.route(
    getNavigationRoute(Page.CUSTOMERS),
    actions.navigation.openCustomers
  );
  router.route(getNavigationRoute(Page.CRM), actions.navigation.openCrmPage);
  router.route(getNavigationRoute(Page.AUTH), actions.navigation.auth);
  try {
    router.start();
  } catch (error) {
    console.error(error);
  }
};
