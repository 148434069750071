import React, { FunctionComponent } from "react";
import { Table, Column } from "@frontend/common/src/components/Table/Table";

import { useTranslation } from "react-i18next";
import { CustomerEmployee } from "@frontend/common/src/types/CustomerEmployee";
import { ActionLink } from "@frontend/common/src/elements/Link/Link";
import { Page } from "@frontend/common/src/types/Page";
import { createUrl } from "../../../../../app/pages";
import { useActions } from "../../../../../overmind";

type ColumnKey = keyof CustomerEmployee;

type Props = {
  customerEmployees: CustomerEmployee[];
  columns?: ColumnKey[];
};
const kundeportalURL = process.env.REACT_APP_KUNDEPORTAL_URL;

const createColumns = (actions: any, t: any): Column<CustomerEmployee>[] => {
  const allColumns: Column<CustomerEmployee>[] = [
    // {
    //   headerName: "Id",
    //   field: "id",
    //   render: (row) => row.id,
    // },
    {
      headerName: t("Name"),
      field: "name",
      render: (row) => row.name,
    },
    // {
    //   headerName: t("Position"),
    //   field: "position",
    //   render: (row) => row.position,
    // },
    {
      headerName: t("Email"),
      field: "email",
      render: (row) => row.email,
    },
    {
      headerName: t("Firma"),
      field: "customer",
      render: (row) => {
        return row.customerId !== undefined ? (
          <div style={{ display: "flex" }}>
            <ActionLink
              href={createUrl(Page.CRM, { customerId: row.customerId })}
            >
              Se på
            </ActionLink>
            <ActionLink
              href={`${kundeportalURL}/emulate/${row.customerId}`}
              target="_blank"
            >
              Velg firma
            </ActionLink>
          </div>
        ) : null;
      },
    },
  ];

  return allColumns;
};

export const CustomerEmployeeList: FunctionComponent<Props> = ({
  customerEmployees,
  columns,
}) => {
  const { t } = useTranslation();
  const actions = useActions();
  const allColumns = createColumns(actions, t);
  let columnsToUse = null;
  if (!columns) {
    columnsToUse = allColumns;
  } else {
    columnsToUse = columns.map((c) => allColumns.find((a) => a.field === c));
  }

  return (
    <Table
      keyField="id"
      data={customerEmployees}
      columns={columnsToUse}
      availableColumns={allColumns}
    />
  );
};
