import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid, Row, Col } from "@frontend/common/src/elements/Grid/Grid";
import { FormInput } from "@frontend/common/src/elements/FormInput/FormInput";
import * as validators from "@frontend//common/src/validators";
import {
  CustomerEmployee,
  UpdateCustomerEmployee,
} from "@frontend/common/src/types/CustomerEmployee";
import { BlockButton } from "@frontend/common/src/elements/Buttons/Buttons.styles";

const validationSchema = yup.object().shape({
  name: validators.name,
  email: validators.requiredEmail,
  phone: validators.string,
  mobile: validators.string,
  position: validators.string,
});

type Props = {
  customerEmployee: CustomerEmployee;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: UpdateCustomerEmployee) => void;
};
export const UserForm: FunctionComponent<Props> = ({
  customerEmployee,
  onSubmit,
}) => {
  const { register, handleSubmit, errors } = useForm<UpdateCustomerEmployee>({
    resolver: yupResolver(validationSchema),
    defaultValues: customerEmployee,
  });

  // AnsvarligSelger_ID
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(onSubmit)(e);
      }}
    >
      <Grid>
        <Row>
          <Col size={1}>
            <input type="hidden" name="active" ref={register} />
            <FormInput
              required
              label="Navn"
              type="text"
              name="name"
              inputRef={register}
              error={errors.name?.message}
            />

            <FormInput
              required
              label="Epost"
              type="text"
              name="email"
              inputRef={register}
              error={errors.email?.message}
            />

            <FormInput
              label="Telefon"
              type="text"
              name="phone"
              inputRef={register}
              error={errors.phone?.message}
            />
            <FormInput
              label="Mobil"
              type="text"
              name="mobile"
              inputRef={register}
              error={errors.mobile?.message}
            />
            <FormInput
              label="Stilling"
              type="text"
              name="position"
              inputRef={register}
              error={errors.position?.message}
            />
          </Col>
        </Row>
      </Grid>

      <div style={{ display: "flex" }}>
        <BlockButton type="submit">
          {customerEmployee != null ? "Oppdater" : "Opprett"}
        </BlockButton>
      </div>
    </form>
  );
};
