import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Col, Grid, Row } from "@frontend/common/src/elements/Grid/Grid";
import { FormInput } from "@frontend/common/src/elements/FormInput/FormInput";
import * as validators from "@frontend/common/src/validators";
import { Customer } from "@frontend/common/src/types/Customer";
import { BlockButton } from "@frontend/common/src/elements/Buttons/Buttons.styles";
import { Label } from "@frontend/common/src/elements/Label/Label";
import { RequiredFieldIndicator } from "@frontend/common/src/elements/RequiredFieldIndicator/RequiredFieldIndicator";
import styled from "styled-components";
import { HBox } from "@frontend/common/src/elements/HBox/HBox";
import { useActions, useAppState, useEffects } from "../../../../overmind";

const InputWrapper = styled(HBox)`
  margin: -10px 0 -9px 0;
  justify-content: space-between;
`;
const validationSchema = yup.object().shape({
  address: validators.address,
  zipcode: validators.zipcode,
  email: validators.requiredEmail,
});

export const CrmForm: FunctionComponent<{ customer: Customer }> = ({
  customer,
}) => {
  const actions = useActions();
  const state = useAppState();
  const effects = useEffects();

  const { register, handleSubmit, errors, watch } = useForm<Customer>({
    resolver: yupResolver(validationSchema),
    defaultValues: customer,
  });
  const address = watch("address");
  const zipcode = watch("zipcode");
  const city = watch("city");

  const onSubmitData = (data: Customer) => {
    // eslint-disable-next-line no-param-reassign
    data.id = state.crmCustomer.id;
    actions.changeCustomer(data);
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(onSubmitData)();
      }}
    >
      <Grid>
        <Row>
          <Col size={1}>
            <FormInput
              required
              label="Kundenavn"
              type="text"
              name="name"
              readonly
              inputRef={register}
              error={errors.name?.message}
            />
            <FormInput
              required
              label="Besøksadresse"
              type="text"
              name="address"
              inputRef={register}
              error={errors.address?.message}
            />
            <Label>
              Postnummer / poststed
              <RequiredFieldIndicator />
            </Label>
            <InputWrapper>
              <div style={{ marginRight: "1rem", width: "100%" }}>
                <FormInput
                  name="zipcode"
                  type="text"
                  inputRef={register}
                  error={errors.zipcode?.message}
                />
              </div>
              <div style={{ marginRight: "1rem", width: "100%" }}>
                <FormInput
                  name="city"
                  type="text"
                  inputRef={register}
                  error={errors.city?.message}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  alignSelf: "center",
                  maxWidth: "7rem",
                  marginTop: 6,
                }}
              >
                {address && zipcode && city ? (
                  <BlockButton
                    as="a"
                    style={{
                      padding: "0.875rem",
                      fontSize: "1.125rem",
                      marginTop: "0.125rem",
                    }}
                    href={`https://www.norgeskart.no/#!?project=norgeskart&zoom=13&layers=panel=searchOptionsPanel&sok=${address}, ${zipcode} ${city}`}
                    target="_blank"
                  >
                    Vis i kart
                  </BlockButton>
                ) : (
                  <BlockButton
                    disabled
                    style={{
                      padding: "0.875rem",
                      fontSize: "1.125rem",
                      marginTop: "0.125rem",
                    }}
                  >
                    Vis i kart
                  </BlockButton>
                )}
              </div>
            </InputWrapper>
            <FormInput
              required
              label="Epost"
              type="text"
              name="email"
              inputRef={register}
              error={errors.email?.message}
            />
            <FormInput
              label="Telefon (sentralbord)"
              type="text"
              name="phone"
              inputRef={register}
              // error={errors.phone?.message}
            />
            {/*
              <TextArea
              label="Standard konstruksjonsopplysninger"
              name="constructionInformation"
              rows={4}
              inputRef={register}
            />
               */}
          </Col>
        </Row>
      </Grid>

      <div style={{ display: "flex", margin: "0 1rem" }}>
        <BlockButton style={{ marginRight: "1rem" }} type="submit">
          Lagre
        </BlockButton>
        <BlockButton onClick={() => effects.router.navigateTo("/kunder")}>
          Avbryt
        </BlockButton>
      </div>
    </form>
  );
};
