import { Customer } from "@frontend/common/src/types/Customer";
import { CustomerEmployee } from "@frontend/common/src/types/CustomerEmployee";
import { endpoints } from "../endpoints";
import { IAdminService } from "./IAdminService";
import { RestClient } from "../../rest/RestClient";

export class AdminService extends RestClient implements IAdminService {
  async search(
    term: string
  ): Promise<{ customers: Customer[]; customerEmployees: CustomerEmployee[] }> {
    const results: any = await this.makeRequest(
      endpoints.customerEmployee.search,
      { term }
    ).then((r) => r.data);

    return {
      customerEmployees: results.customerEmployees.map((ce: any) => {
        const customerEmployee = AdminService.convert(ce);

        if (ce.kunde) {
          customerEmployee.customer = AdminService.convertCustomer(ce.kunde);
        }

        return customerEmployee;
      }),
      customers: results.customers.map(AdminService.convertCustomer),
    };
  }

  static convertCustomer(customer: any): Customer {
    return {
      id: customer.kundenr,
      name: customer.kundenavn,
      address: customer.adresse1,
      zipcode: customer.postnrk,
      city: customer.poststed,
      email: customer.epost,
      drawingsEmail: customer.epost_Tegninger,
      orderConfimEmail: customer.epost_Bestillingsbekreftelse,
      orderConfrimEmailAlt: customer.epost_Ordrebekreftelse,
      phone: customer.telefon,
      constructionInformation: customer.std_konstr_opplysn,
    };
  }

  static convert(employee: any): CustomerEmployee {
    return {
      id: employee.kontaktID,
      name: employee.kontakt_Navn,
      email: employee.kontakt_Epost,
      phone: employee.kontakt_Telefon,
      mobile: employee.kontakt_Mobiltlf,
      position: employee.kontakt_Stilling,
      active: employee.kontakt_BlokkerPortaltilgang,
      hasQuit: employee.kontakt_Slutta,
      customerId: employee.kundenr,
    };
  }
}
