import React, { FunctionComponent, useState } from "react";
import Modal from "@frontend/common/src/components/Modal/Modal";
import { TextArea } from "@frontend/common/src/components/TextArea/TextArea";
import { Header } from "@frontend/common/src/elements/Header/Header";

import { HBox } from "@frontend/common/src/elements/HBox/HBox";
import { VBox } from "@frontend/common/src/elements/VBox/VBox";

import { BlockButton } from "@frontend/common/src/elements/Buttons/Buttons.styles";
import { PhoneLink } from "@frontend/common/src/components/PhoneLink/PhoneLink";
import { useActions, useAppState } from "../../overmind";

export const SendMessageModal: FunctionComponent = () => {
  const [message, setMessage] = useState("");

  const { modals, pretreEmplyees } = useAppState();

  const { modals: modalsActions } = useActions();

  if (!modals.sendMessageModal.isCurrent) {
    return null;
  }

  const employee = pretreEmplyees.find(
    (e) => e.id === modals.sendMessageModal.userId
  );

  return (
    <Modal
      isOpen={modals.sendMessageModal.isCurrent}
      onClose={() => modalsActions.sendMessageModal.close("")}
    >
      <HBox>
        <VBox>
          <Header type="secondary">{employee.name}</Header>

          <PhoneLink number={employee.phone} />
          <a href={`mailto:${employee.email}`}>{employee.email}</a>
        </VBox>

        {employee.photoUrl ? (
          <div style={{ width: "8rem" }}>
            <img src={employee.photoUrl} alt={employee.name} />
          </div>
        ) : null}
      </HBox>
      <TextArea
        style={{ height: "100px" }}
        value={message}
        onChange={(e: any) => setMessage(e.target.value)}
      />
      <BlockButton
        onClick={() => modalsActions.sendMessageModal.close(message)}
      >
        Send melding
      </BlockButton>
    </Modal>
  );
};
