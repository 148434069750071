import { endpoints } from "../endpoints";
import { RestClient } from "../../rest/RestClient";

export class LookupsService extends RestClient {
  async getConfigValue(configKey: string): Promise<string> {
    return await this.makeRequest(endpoints.lookups.getConfigValue, {
      configKey,
    }).then((r) => r.data);
  }
}
