import React, { FunctionComponent } from "react";
import Modal from "@frontend/common/src/components/Modal/Modal";
import { UpdateCustomerEmployee } from "@frontend/common/src/types/CustomerEmployee";
import { UserForm } from "../../components/forms/AdminUserForm/AdminUserForm";
import { useActions, useAppState } from "../../overmind";

export const AdminEditEmployeeModal: FunctionComponent = () => {
  const state = useAppState();
  const actions = useActions();
  const { modals } = useAppState();

  const { modals: modalsActions } = useActions();

  if (!modals.editEmployeeModal.isCurrent) {
    return null;
  }

  const onSubmitData = (data: UpdateCustomerEmployee) => {
    if (modals.editEmployeeModal.employeeId) {
      // eslint-disable-next-line no-param-reassign
      data.id = modals.editEmployeeModal.employeeId;
      actions
        .changeCustomerEmployee(data)
        .then(() => modalsActions.editEmployeeModal.close());
    } else {
      actions
        .adminCreateCustomerEmployee(data)
        .then(() => modalsActions.editEmployeeModal.close());
    }
  };

  return (
    <Modal
      isOpen={modals.editEmployeeModal.isCurrent}
      onClose={() => modalsActions.editEmployeeModal.close()}
    >
      <UserForm
        customerEmployee={state.crmCustomerEmployees.find(
          (e) => e.id === modals.editEmployeeModal.employeeId
        )}
        onSubmit={onSubmitData}
      />
    </Modal>
  );
};
