import { merge, namespaced } from "overmind/config";
import { state } from "./state";
import * as actions from "./actions";
import * as effects from "./effects";
import * as navigation from "./navigation";
import * as auth from "./auth";
import * as admin from "./admin";
import { createModals } from "./createModals";
import * as modals from "./modals/config";

export const config = merge(
  {
    state,
    actions,
    effects,
  },
  namespaced({
    auth,
    admin,
    navigation,
    modals: createModals(modals),
  })
);
