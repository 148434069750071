import styled from "styled-components";

import { PreTreColors } from "../../../styles/constants";

export const ColumnWrapper = styled("div")`
  padding: 20px;
  width: 100%;
  max-width: 550px;
`;
export const PageFlexConstraint = styled.div<{ itemWidth?: string }>`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;
  padding: 2rem 2rem 0 2rem;
  & > * {
    min-width: 300px;
    width: ${(props) => props.itemWidth ?? "20vw"};
  }
`;
export const HeaderWrapper = styled("div")`
  width: 100%;
  padding: 20px 0 0 20px;
`;
export const Text = styled("p")`
  font-size: 1.125rem;
  color: ${PreTreColors.beis};
`;
export const BoldText = styled("p")`
  font-size: 1.125rem;
  color: ${PreTreColors.beis};
  font-weight: bold;
  margin-top: 15px;
`;
export const ButtonSectionWrapper = styled("div")`
  max-width: 30vw;
  margin-left: 20px;
`;

export const ButtonWrapper = styled("div")`
  width: 100%;
  margin-top: 1.25rem;
  min-width: 300px;
`;

export const StyledList = styled.li`
  list-style: none;
  margin: 0;
  padding: 0;
  &:before {
    content: "-";
    margin: 0 0.5rem;
  }
`;

export const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
