import React, { FunctionComponent } from "react";
import { Table, Column } from "@frontend/common/src/components/Table/Table";

import { useTranslation } from "react-i18next";
import { Customer } from "@frontend/common/src/types/Customer";
import { ActionLink } from "@frontend/common/src/elements/Link/Link";
import { Page } from "@frontend/common/src/types/Page";
import { createUrl } from "../../../../../app/pages";
import { useActions } from "../../../../../overmind";

type ColumnKey = keyof Customer;

type Props = {
  customers: Customer[];
  columns?: ColumnKey[];
};
const kundeportalURL = process.env.REACT_APP_KUNDEPORTAL_URL;

const createColumns = (actions: any, t: any): Column<Customer>[] => {
  const allColumns: Column<Customer>[] = [
    {
      headerName: t("Name"),
      field: "name",
      render: (row) => row.name,
    },
    {
      headerName: t("Email"),
      field: "email",
      render: (row) => row.email,
    },
    {
      headerName: t("Actions"),
      render: (row) => (
        <div style={{ display: "flex" }}>
          <ActionLink href={createUrl(Page.CRM, { customerId: row.id })}>
            Se på
          </ActionLink>
          <ActionLink
            href={`${kundeportalURL}/emulate/${row.id}`}
            target="_blank"
          >
            Velg
          </ActionLink>
        </div>
      ),
    },
  ];

  return allColumns;
};

export const CustomerList: FunctionComponent<Props> = ({
  customers,
  columns,
}) => {
  const { t } = useTranslation();
  const actions = useActions();
  const allColumns = createColumns(actions, t);
  let columnsToUse = null;
  if (!columns) {
    columnsToUse = allColumns;
  } else {
    columnsToUse = columns.map((c) => allColumns.find((a) => a.field === c));
  }

  return (
    <Table
      keyField="id"
      data={customers}
      columns={columnsToUse}
      availableColumns={allColumns}
    />
  );
};
