import { Endpoint, Verb } from "./BaseService";

export const endpoints: { [index: string]: { [index: string]: Endpoint } } = {
  auth: {
    login: {
      path: "/auth/logg-inn",
      verb: Verb.POST,
    },
    checkToken: {
      path: "/auth/sjekk-token",
      verb: Verb.GET,
    },
    forgotPassword: {
      path: "/auth/glemt-passord",
      verb: Verb.POST,
    },
    getByUsername: {
      path: "/auth/getUser",
      verb: Verb.POST,
    },
  },
  project: {
    getAllForCustomerId: {
      path: "/kunde/{customerId}/project",
      verb: Verb.GET,
    },
    getById: {
      path: "/kunde/{customerId}/project/{id}",
      verb: Verb.GET,
    },
    getByIdWithoutCustomer: {
      path: "/kunde/placeholder/project/getWithoutCustomer/{id}",
      verb: Verb.GET,
    },
    getOrderFromOfferId: {
      path: "/kunde/{customerId}/project/{offerId}/populate",
      verb: Verb.GET,
    },
    postCommitDraftOrder: {
      path: "/kunde/{customerId}/project/",
      verb: Verb.POST,
    },
    getDocumentsforOrder: {
      path: "/kunde/{customerId}/project/{orderId}/filer",
      verb: Verb.GET,
    },
    getDocumentforOrder: {
      path: "/file/{customerId}/filer/{orderId}",
      verb: Verb.POST,
    },
    addFiles: {
      path: "/file/{orderId}/upload/order/{customerId}",
      verb: Verb.POST,
    },
  },
  lookups: {
    getLookups: {
      path: "/oppslag",
      verb: Verb.GET,
    },
    zipcodeLookup: {
      path: "/oppslag/zip/{zipcode}",
      verb: Verb.GET,
    },
    getConfigValue: {
      path: "/oppslag/config/{configKey}",
      verb: Verb.GET,
    },
  },
  employee: {
    getAll: {
      path: "/ansatt",
      verb: Verb.GET,
    },
    sendMessage: {
      path: "/ansatt/{employeeId}/melding/{customerId}",
      verb: Verb.POST,
    },
  },
  customerEmployee: {
    getAll: {
      path: "/kunde/{customerId}/kontakt",
      verb: Verb.GET,
    },
    search: {
      path: "/admin/sok/kunder",
      verb: Verb.POST,
    },
    update: {
      path: "/kunde/{customerId}/kontakt/{employeeId}",
      verb: Verb.PUT,
    },
    create: {
      path: "/kunde/{customerId}/kontakt",
      verb: Verb.POST,
    },
    resetPassword: {
      path: "/kunde/{customerId}/kontakt/{customerEmployeeId}/resetPassword",
      verb: Verb.GET,
    },
  },
  customer: {
    getById: {
      path: "/kunde/{customerId}",
      verb: Verb.GET,
    },
    update: {
      path: "/kunde/{customerId}",
      verb: Verb.PUT,
    },
    getForToken: {
      path: "/kunde/",
      verb: Verb.GET,
    },
  },
};
