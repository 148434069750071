import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "@frontend/common/src/types/Page";
import { CMSArticles } from "@frontend/common/src/types/CMSArticle";
import { CMSContent } from "../../../components/CMSContent/CMSContent";
import { RoofForm } from "./RoofForm";
import {
  BlockButton,
  ChooseProductPageButton,
  LinkButton,
} from "../../../components/Buttons/Buttons.styles";
import { createUrl } from "../../../app/pages";
import { useActions, useAppState, useEffects } from "../../../overmind";
import { PageFlexConstraint } from "./CreateOrderPage.styles";

import { SubProjects } from "./SubProjects";
import { JoistsForm } from "./JointsForm";
import { PrecutForm } from "./PrecutForm";
import { DraftOrderSubProject } from "../../../types/DraftOrder/DraftOrderSubProject";
import { TechnicalServicesForm } from "./TechnicalServicesForm";
import { Header } from "../../../elements/Header/Header";

export const ChooseProductPage: FunctionComponent = () => {
  const effects = useEffects();
  const actions = useActions();
  const { t } = useTranslation();
  const { orders } = useAppState();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [subProjects, setSubProjects] = useState<DraftOrderSubProject[]>([]);
  const [selectedSubProject, setSelectedSubProject] = useState<
    DraftOrderSubProject | undefined
  >(undefined);
  const [activeForm, setActiveForm] = useState<string>("");
  useEffect(() => {
    if (orders.currentNewOrder.subProjects) {
      setSubProjects(orders.currentNewOrder.subProjects);
    } else if (orders.autofill?.subProjects !== undefined) {
      setSubProjects(orders.autofill.subProjects);
    }
  }, [orders.currentNewOrder.subProjects, orders.autofill]);
  const onSubmit = () => {
    actions.orders.setSubProjectsOnDraftOrder(subProjects);
    effects.router.navigateTo(
      createUrl(Page.CREATE_ORDER, { slug: "vedlegg" })
    );
  };

  const onBackButtonClicked = () => {
    actions.orders.setSubProjectsOnDraftOrder(subProjects);
    effects.router.navigateTo(
      createUrl(Page.CREATE_ORDER, { slug: "kundeinfo" })
    );
  };
  const onFormButtonClick = (form: string) => {
    setActiveForm(form);
    setSelectedSubProject(undefined);
  };
  return (
    <div>
      <PageFlexConstraint>
        <div
          style={{
            minWidth: "300px",
            width: "60vw",
            marginBottom: "1rem",
            marginTop: "-1rem",
          }}
        >
          <Header type="secondary">4. Produktvalg</Header>

          <CMSContent contentId={CMSArticles.ORDER_SELECTION_HELPER} />
        </div>
      </PageFlexConstraint>

      <div style={{ display: "flex", minHeight: "50vh", marginTop: "2em" }}>
        <SubProjects
          selectedSubProject={selectedSubProject}
          setSelectedSubProject={setSelectedSubProject}
          subProjects={subProjects}
          setSubProjects={setSubProjects}
          setActiveForm={setActiveForm}
          autoFill={orders.autofill?.offerId !== undefined}
        />
        <div style={{ width: "70%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginLeft: "2em",
            }}
          >
            <ChooseProductPageButton
              onClick={() => onFormButtonClick("roof")}
              active={activeForm === "roof"}
              disabled={orders.autofill?.offerId !== undefined}
            >
              {t("AddRoof")}
            </ChooseProductPageButton>
            <ChooseProductPageButton
              onClick={() => onFormButtonClick("beamlayer")}
              active={activeForm === "beamlayer"}
              disabled={orders.autofill?.offerId !== undefined}
            >
              {t("AddBeamlayer")}
            </ChooseProductPageButton>
            <ChooseProductPageButton
              onClick={() => onFormButtonClick("precut")}
              active={activeForm === "precut"}
              disabled={orders.autofill?.offerId !== undefined}
            >
              {t("AddPrecut")}
            </ChooseProductPageButton>
            <ChooseProductPageButton
              onClick={() => onFormButtonClick("technical")}
              active={activeForm === "technical"}
              disabled={orders.autofill?.offerId !== undefined}
            >
              {t("AddTechnical")}
            </ChooseProductPageButton>
          </div>
          {activeForm === "roof" && (
            <RoofForm
              setSelectedSubProject={setSelectedSubProject}
              selectedSubProject={selectedSubProject}
              setActiveForm={setActiveForm}
              setSubProjects={setSubProjects}
              subProjects={subProjects}
              autoFill={orders.autofill?.offerId !== undefined}
            />
          )}
          {activeForm === "beamlayer" && (
            <JoistsForm
              setSelectedSubProject={setSelectedSubProject}
              selectedSubProject={selectedSubProject}
              setActiveForm={setActiveForm}
              setSubProjects={setSubProjects}
              subProjects={subProjects}
              autoFill={orders.autofill?.offerId !== undefined}
            />
          )}
          {activeForm === "precut" && (
            <PrecutForm
              setSelectedSubProject={setSelectedSubProject}
              selectedSubProject={selectedSubProject}
              setActiveForm={setActiveForm}
              setSubProjects={setSubProjects}
              subProjects={subProjects}
              autoFill={orders.autofill?.offerId !== undefined}
            />
          )}
          {activeForm === "technical" && (
            <TechnicalServicesForm
              setSelectedSubProject={setSelectedSubProject}
              selectedSubProject={selectedSubProject}
              setActiveForm={setActiveForm}
              setSubProjects={setSubProjects}
              subProjects={subProjects}
              autoFill={orders.autofill?.offerId !== undefined}
            />
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div style={{ display: "flex", width: "30vw", marginTop: "2rem" }}>
          <BlockButton
            disabled={subProjects.length === 0}
            style={{ width: "1000px" }}
            onClick={() => onSubmit()}
          >
            Neste
          </BlockButton>
          <LinkButton as="a" onClick={onBackButtonClicked}>
            {t("Tilbake")}
          </LinkButton>
        </div>
      </div>
    </div>
  );
};
