/* eslint-disable no-underscore-dangle */
import page from "page";
import axios from "axios";

import Toastify from "toastify-js";
import { PreTreColors } from "@frontend/common/src/styles/constants";
import { RouteContext } from "@frontend/common/src/types/RouteContext";
import { AuthService } from "../services/Auth/AuthService";
import "toastify-js/src/toastify.css";
import { EmployeeService } from "../services/employee/EmployeeService";
import { CustomerEmployeeService } from "../services/customerEmployee/CustomerEmployeeService";
import { LookupsService } from "../services/lookups/LookupsService";
import { CustomerService } from "../services/customer/CustomerService";

import { AdminService } from "../services/admin/AdminService";
import { fetchCms } from "../services/cms/CMS";
import { ActivityService } from "../services/activities/ActivityService";

export const cms = {
  fetch: fetchCms,
};

export const api = {
  auth: new AuthService(),
  admin: new AdminService(),
  employees: new EmployeeService(),
  customerEmployee: new CustomerEmployeeService(),
  lookups: new LookupsService(),
  customer: new CustomerService(),
  activity: new ActivityService(),
};

export const router = {
  route(
    url: string,
    action: (payload: RouteContext<{} | void>, next: any) => void
  ) {
    page(url, ({ params, path, pathname, routePath }, next) => {
      action({ params, path, pathname, routePath }, next);
    });
  },
  redirect: page.redirect,
  start: () =>
    page.start({
      click: true,
    }),
  navigateTo: (path: string) => page(path),
};

export const tokenService = {
  get: () => {
    const token = localStorage.getItem("api_token") || null;
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    return token;
  },
  set: (token: string) => {
    localStorage.setItem("api_token", token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  },
  clear: () => {
    localStorage.removeItem("api_token");
    axios.defaults.headers.common.Authorization = "";
  },
};

export const localFileStorage = {
  // @ts-ignore
  _files: undefined,
  set: (files: FileList) => {
    localFileStorage._files = files;
  },
  get: () => localFileStorage._files,
};

export const toast = (text: string, duration: number = 3000) => {
  Toastify({
    text,
    duration,
    gravity: "bottom",
    backgroundColor: PreTreColors.pretre,
  }).showToast();
};

export const persistentCache = {
  get: (key: string, defaultValue: any = null): any => {
    return localStorage.getItem(key) || defaultValue;
  },
  set: (key: string, value: any): void => {
    localStorage.setItem(key, value);
  },
  remove: (key: string): void => {
    return localStorage.removeItem(key);
  },
  clear: (): void => {
    localStorage.clear();
  },
};
