/* eslint-disable no-param-reassign */
import jwt_decode from "jwt-decode";

import { User, UserRole, UserType } from "@frontend/common/src/types/User";
import { createUrl } from "webapp/src/app/pages";
import { Page } from "@frontend/common/src/types/Page";
import { Context } from "..";

const dataOwner = process.env.REACT_APP_AUTH0_ADMINLOCATION;
export const logoutMesterId = async ({ state, effects }: Context) => {
  try {
    effects.tokenService.clear();
    state.customer = null;
    state.currentEmployeeId = null;
    state.auth.user = null;
  } catch (error: any) {
    alert(error.message);
  }
};
// called on successfull login
interface mesterIdLogin {
  token: string;
  username: string;
}
export const onMesterIdLoggedIn = async (
  { state, effects }: Context,
  { token, username }: mesterIdLogin
) => {
  try {
    const decodedToken = jwt_decode(token);
    state.auth.token = token;
    effects.tokenService.set(token);
    const user = await effects.api.customerEmployee.getByUserName(username);
    if (user == null) {
      throw new Error("Ingen tilgang");
    }
    const customers = await effects.api.customer.getByToken();
    // eslint-disable-next-line prefer-destructuring
    state.customer = customers[0];
    state.mesterIdCustomers = customers;
    state.pretreEmplyees = await effects.api.employees.getAll();
    state.customerEmployees =
      await effects.api.customerEmployee.getAllForCustomerId(customers[0].id);
    state.mesterIdLogin = true;
    const { locations } =
      // @ts-ignore
      decodedToken["https://mestergruppen.no/authorization"];
    const stateUser: User = {
      id: user.id,
      customerId: customers[0].id,
      username: user.email,
      role: locations.includes(dataOwner) ? UserRole.ADMIN : UserRole.USER,
      type: UserType.EMPLOYEE,
      isAdmin: locations.includes(dataOwner),
    };
    state.auth.user = stateUser;
    if (stateUser.type === UserType.EMPLOYEE) {
      state.currentEmployeeId = user.id;
    }
    if (!stateUser.isAdmin) {
      effects.tokenService.clear();
      state.customer = null;
      state.currentEmployeeId = null;
      state.auth.user = null;
      effects.router.navigateTo(createUrl(Page.LOGIN));
    }
  } catch (error: any) {
    console.log("Hei");
    effects.tokenService.clear();
    state.customer = null;
    state.currentEmployeeId = null;
    state.auth.user = null;
    effects.router.navigateTo(createUrl(Page.LOGIN));
    effects.toast(error, 5000);
  }
};
