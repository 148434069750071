/* eslint-disable no-param-reassign */
import { Page } from "@frontend/common/src/types/Page";
import { Context } from "..";

export const openLogin = async ({ state }: Context) => {
  state.navigation.currentPage = Page.LOGIN;
};
export const openCustomers = async ({ state }: Context) => {
  state.admin.customerEmployeeSearchResult = [];
  state.admin.customerSearchResult = [];
  state.navigation.currentPage = Page.CUSTOMERS;
};

export const openAdminPage = async ({ state }: Context) => {
  state.navigation.currentPage = Page.ADMIN;
};
export const openCrmPage = async (
  { state, effects }: Context,
  routeArgs: any
) => {
  state.navigation.currentPage = Page.CRM;
  state.crmCustomer = null;
  state.crmCustomerEmployees = [];
  state.crmCustomerActivities = [];
  state.crmActivityTypes = [];
  try {
    state.crmCustomer = await effects.api.customer.getById(
      routeArgs.params.customerId
    );
    state.crmCustomerEmployees =
      await effects.api.customerEmployee.getAllForCustomerId(
        routeArgs.params.customerId
      );
    state.crmCustomerActivities = await effects.api.activity.getAllByCustomerId(
      routeArgs.params.customerId
    );
    state.crmActivityTypes = await effects.api.activity.getAllActivityTypes();
  } catch (errorMessage: any) {
    effects.toast(errorMessage, 5000);
  }
};
export const auth = async ({ state }: Context) => {
  state.navigation.currentPage = Page.AUTH;
};
