import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useActions } from "../../overmind";

const Auth = () => {
  const actions = useActions();
  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((token) => {
        const { email } = user;
        actions.auth.onMesterIdLoggedIn({ token, username: email });
      });
    }
  }, [isAuthenticated]);
  return <div>Autentiserer....</div>;
};

export default Auth;
