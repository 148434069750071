import React, { FunctionComponent } from "react";
import Modal from "@frontend/common/src/components/Modal/Modal";
import { Activity } from "@frontend/common/src/types/Activity";
import { ActivityForm } from "../../components/forms/ActivityForm/ActivityForm";
import { useActions, useAppState } from "../../overmind";

export const AdminEditActivityModal: FunctionComponent = () => {
  const state = useAppState();
  const actions = useActions();
  const { modals } = useAppState();

  const { modals: modalsActions } = useActions();

  if (!modals.editActivityModal.isCurrent) {
    return null;
  }

  const onSubmitData = (data: Activity) => {
    if (modals.editActivityModal.activityId) {
      // eslint-disable-next-line no-param-reassign
      data.id = modals.editActivityModal.activityId;
      actions
        .updateActivity(data)
        .then(() => modalsActions.editActivityModal.close());
    } else {
      actions
        .createActivity(data)
        .then(() => modalsActions.editActivityModal.close());
    }
  };

  return (
    <Modal
      isOpen={modals.editActivityModal.isCurrent}
      onClose={() => modalsActions.editActivityModal.close()}
    >
      <ActivityForm
        activity={state.crmCustomerActivities.find(
          (a: Activity) => a.id === modals.editActivityModal.activityId
        )}
        onSubmit={onSubmitData}
      />
    </Modal>
  );
};
