import React, { FunctionComponent, useEffect } from "react";
import { Table, Column } from "@frontend/common/src/components/Table/Table";
import { Direction } from "@frontend/common/src/components/Table/Direction";
import { CustomerEmployee } from "@frontend/common/src/types/CustomerEmployee";
import {
  BlockButton,
  SelectableBlockButton,
} from "@frontend/common/src/elements/Buttons/Buttons.styles";
import { useActions, useEffects } from "../../../../overmind";

type ColumnKey = keyof CustomerEmployee;

type Props = {
  users: CustomerEmployee[];
  columns?: ColumnKey[];
  // eslint-disable-next-line no-unused-vars
  onRowSelected?: (row: CustomerEmployee) => void;
};

const createColumns = (actions: any): Column<CustomerEmployee>[] => {
  const allColumns: Column<CustomerEmployee>[] = [
    {
      headerName: "Navn",
      field: "name",
      render: (row) => `${row.name}`,
    },
    {
      headerName: "Epost",
      field: "email",
    },
    {
      headerName: "Har sluttet",
      field: "hasQuit",
      render: (row: CustomerEmployee) =>
        row.hasQuit ? (
          <svg height="1rem" viewBox="0 0 24 18">
            <path
              d="M0 9.91357L2.60829 6.52202L9.39163 12.2615L20.3488 0L23.4791 2.86985L9.65201 18L0 9.91357Z"
              fill="#CA0000"
            />
          </svg>
        ) : (
          ""
        ),
    },
    {
      headerName: "Aktiv",
      field: "active",
      render: (row) => (
        <BlockButton
          style={{ fontSize: ".75rem", padding: ".2rem" }}
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();

            actions.changeCrmUserActive({
              userId: row.id,
              activeState: !row.active,
            });
          }}
        >
          {row.active ? "Deaktiver" : "Aktiver"}
        </BlockButton>
      ),
    },
    {
      headerName: "Har passord",
      field: "hasPassword",
      render: (row: CustomerEmployee) =>
        row.hasPassword ? (
          <svg height="1rem" viewBox="0 0 24 18">
            <path
              d="M0 9.91357L2.60829 6.52202L9.39163 12.2615L20.3488 0L23.4791 2.86985L9.65201 18L0 9.91357Z"
              fill="#CA0000"
            />
          </svg>
        ) : null,
    },
    {
      headerName: "Handlinger",
      field: "hasPassword",
      render: (row) => (
        <div style={{ display: "flex" }}>
          <BlockButton
            style={{ fontSize: ".75rem", padding: ".2rem" }}
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();

              actions.changeCrmUserHasQuit({ userId: row.id, hasQuit: true });
            }}
          >
            Merk sluttet
          </BlockButton>
        </div>
      ),
    },
  ];

  return allColumns;
};

export const CrmUserList: FunctionComponent<Props> = ({
  users,
  columns,
  onRowSelected,
}) => {
  const actions = useActions();
  const allColumns = createColumns(actions);

  const effects = useEffects();
  const [includePastEmployees, setIncludePastEmployees] = React.useState(false);

  useEffect(() => {
    effects.api.lookups
      .getConfigValue("ViseSluttaForAdmin")
      .then((configValue: string) => {
        setIncludePastEmployees(parseInt(configValue, 10) === 1);
      });
  }, []);

  const [sortOption, setSortOption] = React.useState<{
    field: keyof CustomerEmployee;
    direction: Direction;
  }>({
    field: "name",
    direction: Direction.ascending,
  });
  let columnsToUse = null;
  if (!columns) {
    columnsToUse = allColumns;
  } else {
    columnsToUse = columns.map((c) => allColumns.find((a) => a.field === c));
  }
  return (
    <>
      {/* Tabs (Menu options) */}
      <div
        style={{
          display: "flex",
          width: "100%",
          maxWidth: "40rem",
          marginBottom: "1rem",
        }}
      >
        <BlockButton style={{ marginRight: "1rem" }}>
          Kontaktpersoner
        </BlockButton>
        <SelectableBlockButton onClick={() => actions.onChangeCrmActiveStep(1)}>
          Aktiviteter
        </SelectableBlockButton>
      </div>

      <Table
        keyField="id"
        data={users
          .filter((u) => includePastEmployees || !u.hasQuit)
          .sort((a, b) => {
            if (sortOption) {
              if (sortOption.direction === Direction.ascending) {
                return a[sortOption.field] > b[sortOption.field] ? 1 : -1;
              }
              return a[sortOption.field] < b[sortOption.field] ? 1 : -1;
            }
            return 0;
          })}
        onSortChanged={(field, direction) =>
          setSortOption({ field, direction })
        }
        currentSortOption={sortOption}
        columns={columnsToUse}
        availableColumns={allColumns}
        onRowSelected={onRowSelected}
      />
    </>
  );
};
