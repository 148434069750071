import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { TextArea } from "@frontend/common/src/components/TextArea/TextArea";
import { CMSArticles } from "@frontend/common/src/types/CMSArticle";
import * as validators from "@frontend/common/src/validators";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CMSContent } from "../../../components/CMSContent/CMSContent";
import { useAppState } from "../../../overmind";

import { Select } from "../../../components/Select/Select";

import { OfferOptionType } from "../../../types/OfferOption";
import { FormProps } from "./RoofForm";
import { BlockButton } from "../../../components/Buttons/Buttons.styles";
import { DraftOrderSubProject } from "../../../types/DraftOrder/DraftOrderSubProject";
import { makeId } from "../../../helpers/GenerateId";

const precut = yup.object().shape({
  dimensions: validators.string,
  comment: validators.string,
});

export const PrecutForm: FunctionComponent<FormProps> = ({
  setSubProjects,
  setActiveForm,
  subProjects,
  selectedSubProject,
  setSelectedSubProject,
  autoFill,
}) => {
  const { t } = useTranslation();
  const { offerOptions } = useAppState();
  const onSubmit = (data: DraftOrderSubProject) => {
    if (data.projectId === undefined || data.projectId.length < 1) {
      // eslint-disable-next-line no-param-reassign
      data.projectId = makeId(8);
    }
    if (selectedSubProject !== undefined) {
      const newProjs = subProjects.map((x) => {
        if (x.projectId === data.projectId) {
          return {
            ...x,
            comment: data.comment,
            dimensions: data.dimensions ?? selectedSubProject.dimensions,
          };
        }
        return x;
      });
      setSubProjects(newProjs);
    } else {
      setSubProjects([...subProjects, data]);
    }
    setActiveForm("");
    setSelectedSubProject(undefined);
  };
  const onCancel = () => {
    setActiveForm("");
    setSelectedSubProject(undefined);
  };
  const dimensions = offerOptions
    .filter((o) => o.type === OfferOptionType.PRECUT_DIMENSION)
    .map((o) => ({ label: o.description, value: o.id }));
  const { register, handleSubmit, errors } = useForm<DraftOrderSubProject>({
    resolver: yupResolver(precut),
    defaultValues: selectedSubProject,
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          width: "100%",
        }}
      >
        <div>
          <input ref={register} type="hidden" value="precut" name="type" />
          <input ref={register} type="hidden" name="projectId" />

          <Select
            name="dimensions"
            label={t("Dimension")}
            options={dimensions}
            inputRef={register}
            error={errors.dimensions?.message}
            required
            disabled={
              autoFill &&
              selectedSubProject.dimensions !== null &&
              selectedSubProject.dimensions.length > 0
            }
          />

          <TextArea
            label={t("Kommentar")}
            name="comment"
            rows={3}
            inputRef={register}
            placeholder={t("Write other required info here")}
            defaultValue={errors.comment?.message}
          />
          <CMSContent contentId={CMSArticles.PRECUT_INFO} />
        </div>
        <div
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            width: "30%",
          }}
        >
          <BlockButton style={{ marginBottom: "2em" }} type="submit">
            {selectedSubProject !== undefined ? "Oppdater" : "Legg til"}
          </BlockButton>
          <BlockButton onClick={() => onCancel()} type="button">
            Avbryt
          </BlockButton>
        </div>
      </div>
    </form>
  );
};
