import React, { FunctionComponent, ReactChild } from "react";
import { useTranslation } from "react-i18next";
import { Col, Grid, Row } from "@frontend/common/src/elements/Grid/Grid";
import { Page } from "@frontend/common/src/elements/Page/Page";
import { Page as PageConstants } from "@frontend/common/src/types/Page";
import { SalesPersonBox } from "webapp/src/components/SalesPersonBox/SalesPersonBox";
import { Activity } from "@frontend/common/src/types/Activity";
import { CustomerEmployee } from "@frontend/common/src/types/CustomerEmployee";
import { createUrl } from "../../../app/pages";
import { useActions, useAppState } from "../../../overmind";
import { CrmSteps } from "../../../types/CrmSteps";
import { AdminEditActivityModal } from "../../../modals/AdminEditActivityModal/AdminEditActivityModal";
import { AdminEditEmployeeModal } from "../../../modals/AdminEditEmployeeModal/AdminEditEmployeeModal";
import { CrmActivities } from "./components/CrmActivities";
import { CrmUserList } from "./components/CrmUserList";
import { CrmForm } from "./components/CrmForm";

export const CrmPage: FunctionComponent = () => {
  const state = useAppState();
  const actions = useActions();

  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const steps: { [key in CrmSteps]: { component: ReactChild; slug: string } } =
    {
      [CrmSteps.CONTACT_PERSONS]: {
        component: (
          <CrmUserList
            users={state.crmCustomerEmployees}
            onRowSelected={(customerEmployee: CustomerEmployee) => {
              actions.onEditEmployee(customerEmployee.id);
            }}
          />
        ),
        slug:
          state.activeCrmStep === 0
            ? createUrl(PageConstants.CRM, { slug: "personer" })
            : "#",
      },
      [CrmSteps.ACTIVITIES]: {
        component: (
          <CrmActivities
            activities={state.crmCustomerActivities}
            onCreateActivity={() => {
              actions.onEditActivity();
            }}
            onRowSelected={(activity: Activity) => {
              actions.onEditActivity(activity.id);
            }}
          />
        ),
        slug:
          state.activeCrmStep === 1
            ? createUrl(PageConstants.CRM, { slug: "aktiviteter" })
            : "#",
      },
    };

  return (
    <Page
      title={t("Rediger kunde - {{companyName}}", {
        companyName: state.crmCustomer?.name || "Laster..",
      })}
    >
      <Grid>
        <Row>
          <Col size={1}>
            {state.crmCustomer ? (
              <CrmForm customer={state.crmCustomer} />
            ) : null}
            <SalesPersonBox employeeId={state.crmCustomer?.salesPersonId} />
          </Col>
          <Col size={2}>
            {/* Swappable list */}
            {steps[state.activeCrmStep].component}
          </Col>
        </Row>
      </Grid>

      {state.modals.editEmployeeModal.isCurrent ? (
        <AdminEditEmployeeModal />
      ) : null}
      {state.modals.editActivityModal.isCurrent ? (
        <AdminEditActivityModal />
      ) : null}
    </Page>
  );
};
