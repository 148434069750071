import React, { FunctionComponent, ReactChild } from "react";
import { useTranslation } from "react-i18next";
import { Page as PageConstants } from "@frontend/common/src/types/Page";
import { Page } from "@frontend/common/src/elements/Page/Page";
import { createUrl } from "../../../app/pages";
// Elements
import { useAppState } from "../../../overmind";
import { CreateOrderSteps } from "../../../types/CreateOrderSteps";
import { BreadCrumbs } from "../../../components/BreadCrumbs/BreadCrumbs";
import { CustomerInformation } from "./CustomerInformation";
import { ChooseProductPage } from "../ChooseProductPage/ChooseProductPage";

import { Attachments } from "../ChooseProductPage/Attachments";
import { Summary } from "../ChooseProductPage/Summary";
import { Receipt } from "../ChooseProductPage/Receipt";

import { SelectionGridModal } from "../../../modals/SelectionGridModal/SelectionGridModal";
import { ChooseProjectType } from "./ChooseProjectType";

const createSteps = (t: any, lastCompletedOrderStep: number) => {
  const steps: {
    // eslint-disable-next-line no-unused-vars
    [key in CreateOrderSteps]: {
      title: string;
      component: ReactChild;
      slug: string;
    };
  } = {
    [CreateOrderSteps.PROJECT_TYPE]: {
      title: "Velg type",
      component: <ChooseProjectType />,
      slug:
        lastCompletedOrderStep >= CreateOrderSteps.PROJECT_TYPE
          ? createUrl(PageConstants.CREATE_ORDER, { slug: "velg" })
          : "#",
    },
    [CreateOrderSteps.CUSTOMER_INFO]: {
      title: t("Kundeinfo / Byggeplass / Levering"),
      component: <CustomerInformation />,
      slug:
        lastCompletedOrderStep >= CreateOrderSteps.CUSTOMER_INFO
          ? createUrl(PageConstants.CREATE_ORDER, { slug: "kundeinfo" })
          : "#",
    },
    [CreateOrderSteps.CHOOSE_PRODUCT]: {
      title: t("Produktvalg"),
      component: <ChooseProductPage />,
      slug:
        lastCompletedOrderStep >= CreateOrderSteps.CHOOSE_PRODUCT
          ? createUrl(PageConstants.CREATE_ORDER, { slug: "produktvalg" })
          : "#",
    },
    [CreateOrderSteps.ATTACHMENTS]: {
      title: t("Vedlegg"),
      component: <Attachments />,
      slug:
        lastCompletedOrderStep >= CreateOrderSteps.ATTACHMENTS
          ? createUrl(PageConstants.CREATE_ORDER, { slug: "vedlegg" })
          : "#",
    },
    [CreateOrderSteps.SUMMARY]: {
      title: t("Oppsummering"),
      component: <Summary />,
      slug:
        lastCompletedOrderStep >= CreateOrderSteps.SUMMARY
          ? createUrl(PageConstants.CREATE_ORDER, { slug: "oppsummering" })
          : "#",
    },
    [CreateOrderSteps.RECEIPT]: {
      title: t("Kvittering"),
      component: <Receipt />,
      slug:
        lastCompletedOrderStep >= CreateOrderSteps.RECEIPT
          ? createUrl(PageConstants.CREATE_ORDER, { slug: "kvittering" })
          : "#",
    },
  };

  return steps;
};

export const CreateOrderPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    orders,
    activeCreateOrderStep,
    modals: { selectionGridModal },
  } = useAppState();
  const steps = createSteps(t, orders.lastCompletedOrderStep);

  return (
    <>
      <Page
        title={
          activeCreateOrderStep === 0
            ? "Nytt prosjekt"
            : orders.isQuote
            ? "Ny forespørsel"
            : "Ny bestilling"
        }
        headerItems={
          <BreadCrumbs
            steps={Object.values(steps)}
            activeStep={activeCreateOrderStep}
          />
        }
      >
        <div>{steps[activeCreateOrderStep].component}</div>
      </Page>
      {selectionGridModal.isCurrent ? <SelectionGridModal /> : null}
    </>
  );
};
