import {
  Activity,
  ActivityType,
  OpprettAktivitet,
} from "@frontend/common/src/types/Activity";
import { DateFormat, formatDate } from "@frontend/common/src/helpers/date";
import { endpoints } from "../endpoints";
import { RestClient } from "../../rest/RestClient";
import { IActivityService } from "./IActivityService";

export class ActivityService extends RestClient implements IActivityService {
  async getAllByCustomerId(customerId: string): Promise<Activity[]> {
    const results: any = await this.makeRequest(
      endpoints.activity.getAllByCustomerId,
      { customerId }
    ).then((r) => r.data);
    return results.map(ActivityService.convert);
  }

  async getAllActivityTypes(): Promise<ActivityType[]> {
    const results: any = await this.makeRequest(
      endpoints.activity.getAllActivityTypes
    ).then((r) => r.data);
    return results.map(ActivityService.convertType);
  }

  async create(customerId: string, activity: Activity) {
    const createActivityObj: OpprettAktivitet = {
      overskrift: activity.headline,
      dato: activity.regDate,
      oppgaveType: activity.taskTypeId,
      utfort: String(activity.completed) === "true",
      utfortAv:
        String(activity.pretreEmployeeId) === ""
          ? null
          : String(activity.pretreEmployeeId),
      selger: activity.customerContactId,
      notat: activity.note,
    };

    await this.makeRequest(endpoints.activity.create, {
      customerId,
      ...createActivityObj,
    });
  }

  async update(customerId: string, activity: Activity): Promise<boolean> {
    return await this.makeRequest(endpoints.activity.update, {
      customerId,
      activityId: activity.id,
      overskrift: activity.headline,
      dato: activity.regDate,
      oppgaveType: activity.taskTypeId,
      utfort: String(activity.completed) === "true",
      utfortAv:
        String(activity.pretreEmployeeId) === ""
          ? null
          : activity.pretreEmployeeId,
      selger: activity.customerContactId,
      notat: activity.note,
    }).then((r) => r.data);
  }

  static convert(activity: any): Activity {
    return {
      id: activity.oppgaveID,
      headline: activity.overskrift,
      regDate: formatDate(new Date(activity.regdate), DateFormat.ISO_DATE),
      taskTypeName: activity.oppgavetypeNavn,

      completed: activity.utfort,
      completedDate: formatDate(
        new Date(activity.utfortDato),
        DateFormat.ISO_DATE
      ),
      customerContactId: activity.kundekontaktID,
      customerContactName: activity.kundekontaktNavn,
      customerId: activity.kundeNr,
      customerName: activity.kundenavn,
      note: activity.notat,
      pretreEmployeeId: activity.pretreAnsattID,
      pretreEmployeeName: activity.pretreAnsattNavn,
      taskTypeId: activity.oppgaveTypeID,
    };
  }

  static convertType(activityType: any): ActivityType {
    return {
      id: activityType.oppgaveTypeID,
      name: activityType.oppgaveTypeNavn,
    };
  }
}
