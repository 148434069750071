import { Endpoint, Verb } from "@frontend/common/src/rest/RestClient";

export const endpoints: { [index: string]: { [index: string]: Endpoint } } = {
  auth: {
    login: {
      path: "/auth/logg-inn",
      verb: Verb.POST,
    },
    checkToken: {
      path: "/auth/sjekk-token",
      verb: Verb.GET,
    },
    forgotPassword: {
      path: "/auth/glemt-passord",
      verb: Verb.POST,
    },
    getByUsername: {
      path: "/auth/getUser",
      verb: Verb.POST,
    },
  },
  activity: {
    getAllByCustomerId: {
      path: "/aktivitet/{customerId}",
      verb: Verb.GET,
    },
    getAllActivityTypes: {
      path: "/aktivitet/types",
      verb: Verb.GET,
    },
    create: {
      path: "/aktivitet/{customerId}",
      verb: Verb.POST,
    },
    update: {
      path: "/aktivitet/{customerId}",
      verb: Verb.PUT,
    },
  },
  lookups: {
    getConfigValue: {
      path: "/oppslag/config/{configKey}",
      verb: Verb.GET,
    },
  },
  employee: {
    getAll: {
      path: "/ansatt",
      verb: Verb.GET,
    },
    sendMessage: {
      path: "/ansatt/{employeeId}/melding/{customerId}",
      verb: Verb.POST,
    },
  },
  customerEmployee: {
    getAll: {
      path: "/kunde/{customerId}/kontakt",
      verb: Verb.GET,
    },
    search: {
      path: "/admin/sok/kunder",
      verb: Verb.POST,
    },
    update: {
      path: "/kunde/{customerId}/kontakt/{employeeId}",
      verb: Verb.PUT,
    },
    create: {
      path: "/kunde/{customerId}/kontakt",
      verb: Verb.POST,
    },
    resetPassword: {
      path: "/kunde/{customerId}/kontakt/{customerEmployeeId}/resetPassword",
      verb: Verb.GET,
    },
  },
  customer: {
    getById: {
      path: "/kunde/{customerId}",
      verb: Verb.GET,
    },
    update: {
      path: "/kunde/{customerId}",
      verb: Verb.PUT,
    },
    getForToken: {
      path: "/kunde/",
      verb: Verb.GET,
    },
  },
};
