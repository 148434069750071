import { Page } from "@frontend/common/src/types/Page";
import Auth from "webapp/src/pages/Auth/Auth";
import { LoginPage } from "../pages/Guest/LoginPage/LoginPage";

import { CustomerListPage } from "../pages/admin/CustomerListPage/CustomerListPage";
import { AdminPage } from "../pages/admin/AdminPage/AdminPage";

import { CrmPage } from "../pages/admin/CrmPage/CrmPage";

import { ErrorPage } from "../pages/error/ErrorPage";

// eslint-disable-next-line no-shadow
export enum PageAccess {
  GUEST,
  AUTH,
}

type PageConfig = {
  component: any;
  restrictedTo: PageAccess | null;
  route: string;
};
export const pages: { [index: string]: PageConfig } = {
  [Page.LOGIN]: {
    component: LoginPage,
    restrictedTo: PageAccess.GUEST,
    route: "/logg-inn",
  },
  [Page.CUSTOMERS]: {
    component: CustomerListPage,
    restrictedTo: PageAccess.AUTH, // TODO Set this to ADMIN when implemented
    route: "/kunder",
  },
  [Page.ADMIN]: {
    component: AdminPage,
    restrictedTo: PageAccess.AUTH, // TODO Set this to ADMIN when implemented
    route: "/admin",
  },
  [Page.CRM]: {
    component: CrmPage,
    restrictedTo: PageAccess.AUTH, // TODO Set this to ADMIN when implemented
    route: "/crm/:customerId/:slug?",
  },
  [Page.ERROR]: {
    component: ErrorPage,
    restrictedTo: null,
    route: "/error",
  },
  [Page.AUTH]: {
    restrictedTo: PageAccess.GUEST,
    route: "/auth",
    component: Auth,
  },
};

export const getNavigationRoute = (page: Page): string => {
  const p = pages[page];

  if (!pages[page]) {
    throw new Error(`Page not found: ${page}`);
  }

  return p.route;
};

export const createUrl = (
  page: Page,
  params: { [index: string]: string | number } = null
): string => {
  const p = pages[page];

  if (!pages[page]) {
    throw new Error(`Page not found: ${page}`);
  }

  let { route } = p;
  if (params) {
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in params) {
      route = route.replace(`:${key}`, params[key] as string); // replace required key
      route = route.replace(`:${key}?`, params[key] as string); // replace optional key
    }
  }

  route = route.replace(/:.+\?/gi, "");
  route = route.replace(/\/\//gi, "/");

  return route;
};
